import React, { useState, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { Pause, Redo, SyncAlt, Undo, Replay, Home } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { useParams, useHistory } from 'react-router-dom';

const Timer = ({ timeout }) => {
  const history = useHistory();
  const [time, setTime] = useState(timeout);
  const [isActive, setIsActive] = useState(true);
  const [hasTimedOut, setHasTimedOut] = useState(false);

  useEffect(() => {
    let interval = null;

    if (isActive && time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    if (time === 0) {
      setHasTimedOut(true);
      setIsActive(false);
    }

    return () => clearInterval(interval);
  }, [isActive, time]);

  const browseHome = () => {
    history.push('/#/');
  };
  const handleRestart = () => {
    setTime(timeout);
    setIsActive(true);
    setHasTimedOut(false);
    // Reload current page after 2 seconds
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handlePauseStartToggle = () => {
    setIsActive((prevState) => !prevState);
  };

  return (
    <div>
      {hasTimedOut ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'black',
            opacity: 0.7,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            zIndex: 9999,
          }}
        > 
          <div className="timeOutMessage">
          <p>Your exam timed out. Please restart.</p>
          <Button variant="contained" onClick={handleRestart} startIcon={<Replay />}>
            Restart
          </Button>
          <Button variant="contained" onClick={browseHome} startIcon={<Home />}>
            Home
          </Button>
          </div>
        </div>
      ) : (
        <>
          <Typography variant="h6" onClick={handlePauseStartToggle} >
  {/*
  <Box component="span" mr={1}>
    {isActive ? <Pause /> : <SyncAlt />}
  </Box>
*/}
Time Remaining: {Math.floor(time / 60)}m {time % 60}s
</Typography>

        </>
      )}
    </div>
  );
};

export default Timer;
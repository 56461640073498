import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import SiteNavbar from './components/SiteHeader';
import SiteFooter from './components/SiteFooter';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import InfoIcon from '@material-ui/icons/Info';
import DoubleArrowIcon from '@material-ui/icons/NavigateNext';
import DoubleArrowIconPrevious from '@material-ui/icons/NavigateBefore';
import HomeIcon from '@material-ui/icons/Home';
import TwitterIcon from '@material-ui/icons/Twitter';
import GithubIcon from '@material-ui/icons/GitHub';
import YoutubeIcon from '@material-ui/icons/YouTube';
import WebsiteIcon from '@material-ui/icons/ImportantDevices';
import SchoolIcon from '@material-ui/icons/School';
import TechStack from '@material-ui/icons/Code';
import TagIcon from '@material-ui/icons/Style';
import TopicIcon from '@material-ui/icons/CastForEducation';
import TrainingIcon from '@material-ui/icons/School';
import LearningIcon from '@material-ui/icons/LocalLibrary';
import WebsitesIcon from '@material-ui/icons/Language';
import ReditIcon from '@material-ui/icons/Reddit';
import DiscussionIcon from '@material-ui/icons/Chat';
import { fetchSiteData } from './components/SiteData';
import DisqusComments from './components/DisqusComments';
import { AuthContext } from './context/AuthContext';

const ExamDetailsPage = () => {
const [data, setData] = useState({});
const examURL = process.env.REACT_APP_EXAMS_URL;
const [loading, setLoading] = useState(true);

const [siteData, setSiteData] = useState({});
    const { token, user, isAuthenticated, checkAccessAndGenerateLink } = useContext(AuthContext);
     const [appHome, setappHome] = useState('');
    const [appLevelText, setappLevelText] = useState('');
    const [appCopyright, setappCopyright] = useState('');
    const [appTopicText, setappTopicText] = useState('');
    const [appTagsText, setappTagsText] = useState('');
    const [appExamStartText, setappExamStartText] = useState('');
    const [appNextText, setappNextText] = useState('');
    const [appPreviousText, setappPreviousText] = useState('');
     const [appNoExamText, setappNoExamText] = useState('');
     const [appLearnText, setappLearnText] = useState('');
     const { examSubject, examID } = useParams();
        
     // Extract the query string from the current URL
  const queryString = window.location.search;

  // Use URLSearchParams to parse the query string
  const urlParams = new URLSearchParams(queryString);

  // Get the value of the 'lang' parameter
  const langValue = urlParams.get('lang');
  const subId = urlParams.get('subId');




  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const url = `${examURL}details.php?examSubject=${examSubject}&examSubjectID=${examID}&lang=${langValue}`;
      const response = await axios.get(url);
      setData(response.data);
      setLoading(false);

      //console.log(response.data);


       const fetchedData = await fetchSiteData(/* provide any required parameters here, if needed */);
           setSiteData(fetchedData); // Step 2: Fetch site data and set it in state
         //  console.log(siteData);
           setappCopyright(fetchedData.projectInfo.APP_COPYRIGHT);
           setappLevelText(fetchedData.projectInfoGeneral.APP_EXAM_LEVEL);
           setappTopicText(fetchedData.projectInfoGeneral.APP_EXAM_TOPIC);
           setappTagsText(fetchedData.projectInfoGeneral.APP_EXAM_TAGS);
           setappExamStartText(fetchedData.projectInfoGeneral.APP_EXAM_START);
           setappNextText(fetchedData.projectInfoGeneral.APP_PAGE_NEXT);
           setappPreviousText(fetchedData.projectInfoGeneral.APP_PAGE_PREVIOUS);
           setappNoExamText(fetchedData.projectInfoGeneral.APP_EXAM_EMPTY);
           setappHome(fetchedData.projectPages.home.menu_text);
            setappLearnText(fetchedData.projectInfoGeneral.APP_EXAM_LEARN);

            //console.log(appHome);


    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }


  return (
  <React.Fragment>
       <SiteNavbar  Home={appHome}/>
    <div className="container itemDetails">
     <div className="detailsText">
      <div className="examDetails">
      <img className="technologyLogo" src={data.imageKey} alt={data.title} />
      <h1 className="technologyHeadline freeExamsHeadline examDetails">{data.title}</h1>
      <p className="examLevel"> <span class="tagHeadline">Exam Level : </span>{data.level} </p>
      <p className="examDescription"> {data.description} </p>
      <p className="examToppics"> <span class="tagHeadline">Topics : </span> {data.topics} </p>
      <p className="examTags"><span class="tagHeadline">Tags : </span> {data.tags} </p>
    {/*<p className="examTags"><span class="tagHeadline">Participants : </span> 100 </p> */}
      </div>

<div className="quickLinks">
<a href={`/examslist/${examSubject}/3?lang=${langValue}`} className="examList detailsLink freeExamsHeadline">
<h5 className="freeExamsHome">{appHome} {<InfoIcon />}</h5>

 </a>
<a className="detailsLink freeExamsHeadline" href= {checkAccessAndGenerateLink(data.level, examID, examSubject, langValue)} target="_blank">
               <h5 className="freeExamsHeadline"> {appExamStartText} <DoubleArrowIcon /> </h5>
              </a>
</div>
      <DisqusComments />
    </div>
    </div>




     <SiteFooter CopyRight={appCopyright} />  {/* Step 3: Pass the prop to SiteFooter */}
    </React.Fragment>
  );
};

export default ExamDetailsPage;
import React, { useEffect } from 'react';

const DisqusComments = () => {
  useEffect(() => {
    // Dynamically get the current page URL
    const pageUrl = window.location.href;

    // Use pathname as a unique page identifier
    const pageIdentifier = window.location.pathname;

    window.disqus_config = function () {
      this.page.url = pageUrl;
      this.page.identifier = pageIdentifier;
    };

    // Create the Disqus script
    const script = document.createElement('script');
    script.src = 'https://trialtest.disqus.com/embed.js';
    script.setAttribute('data-timestamp', +new Date());
    document.body.appendChild(script);

    // Clean up
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures this runs once on mount

  return <div id="disqus_thread"></div>;
};

export default DisqusComments;

import React from 'react';

class PreventCopyPaste extends React.Component {

  handleEvent = (event) => {
    event.preventDefault();
  };  // <-- Ensure semicolons if you're using class property syntax

  handleKeyDown = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 67) {
      event.preventDefault();
    }
  };  // <-- Ensure semicolons if you're using class property syntax

  render() {
    return (
      <div
        onContextMenu={this.handleEvent}
        onCut={this.handleEvent}
        onCopy={this.handleEvent}
        onPaste={this.handleEvent}
        onKeyDown={this.handleKeyDown}
      >
        {this.props.children}
      </div>
    );
  }
}

export default PreventCopyPaste;
import React, { useState, useEffect, useContext } from "react";
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeIcon from '@material-ui/icons/Home';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext'; // Adjust the path as needed
import SiteNavbar from '../SiteHeader';
import SiteFooter from '../SiteFooter';
import { fetchSiteData } from '../SiteData';

const Profile = () => {
    const [data, setData] = useState([]);
    const [profileData, setProfileData] = useState(null);
    const { userId } = useParams(); // Get userId from URL
    const { token } = useContext(AuthContext); // Retrieve token from AuthContext
    const { isAuthenticated, user, logout } = useContext(AuthContext);
    const [appCopyright, setappCopyright] = useState('');
    const [appHome, setappHome] = useState('');

     useEffect(() => {
    const fetchDataFromAPI = async () => {
      try {
        const apiData = await fetchSiteData();
        setData(apiData);
       // console.log(apiData);


        if (apiData.projectInfo) {
           setappCopyright(apiData.projectInfo.APP_COPYRIGHT);
           setappHome(apiData.projectPages.home.menu_text);
          
        }
        
      } catch (error) {
        // Handle the error
      }
    };

    fetchDataFromAPI();
  }, []);

// console.log(user);
    if (!user) return <div>Loading...</div>;
console.log(user.user_data);
    return (
     <React.Fragment>
      <SiteNavbar  Home={appHome}/>
          <div className="mainWrapper">
                <div className="loginForm registrationForm">
                    <h2 className="loginFormHeadline">Profile</h2>

            <div className="profileDetails">
            <img src={user.user_data.avatar} className="profileImage" alt={user.user_data.display_name} />
            <p><strong>Username:</strong> {user.user_data.username}</p>
            <p><strong>First Name:</strong> {user.user_data.first_name}</p>
            <p><strong>Last Name:</strong> {user.user_data.last_name}</p>
             <p><strong>Display Name:</strong> {user.user_data.display_name}</p>
            <p><strong>Nickname:</strong> {user.user_data.display_name}</p>
            <p><strong>Email:</strong> {user.user_data.email}</p>
            <p><strong>Bio:</strong> {user.user_data.description}</p>
            <p><strong>Website:</strong> {user.user_data.url}</p>
            <p><strong>Language:</strong> {user.user_data.local}</p>

            {/* Add more fields as per the data structure of your user profile */}
        </div>
          <div className="quickLinks">
          <a className="quickLinkRegister editProfile" href={`/profile/edit/${user.user_data.user_id}`}>Update Profile</a>          
        </div>

        </div>
        </div>
        <SiteFooter CopyRight={appCopyright} />
        </React.Fragment>
    );
};

export default Profile;
import React, { useState, useEffect, useContext } from "react";
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeIcon from '@material-ui/icons/Home';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import SiteNavbar from '../SiteHeader';
import SiteFooter from '../SiteFooter';
import { fetchSiteData } from '../SiteData';

const EditProfile = () => {
    const { userId } = useParams();
    const { token, user } = useContext(AuthContext);
    const [profileData, setProfileData] = useState({
        avatar: '',
        username: '',
        first_name: '',
        last_name: '',
        display_name: '',
        email: '',
        bio: '',
        url: '',
        nickname: '',
        local: '',
    });
    const [appCopyright, setappCopyright] = useState('');
    const [appHome, setappHome] = useState('');

    useEffect(() => {
        const fetchDataFromAPI = async () => {
            try {
                const apiData = await fetchSiteData();
                // Set any other state or data you need from the API
                if (apiData.projectInfo) {
                    setappCopyright(apiData.projectInfo.APP_COPYRIGHT);
                    setappHome(apiData.projectPages.home.menu_text);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataFromAPI();
    }, []);

    useEffect(() => {
        // Initialize with user data if available
        if (user && user.user_data) {
            setProfileData({
                avatar: user.user_data.avatar,
                username: user.user_data.username,
                first_name: user.user_data.first_name,
                last_name: user.user_data.last_name,
                display_name: user.user_data.display_name,
                email: user.user_data.email,
                bio: user.user_data.description,
                url: user.user_data.url,
                nickname: user.user_data.nickname,
                local: user.user_data.local,
                // Initialize other fields as needed
            });
        }
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (event) => {
            setProfileData({ ...profileData, avatar: event.target.result });
        };
        reader.readAsDataURL(e.target.files[0]);
    }
};

    const handleSubmit = async (e) => {
        e.preventDefault();
        const config = {
    headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    },
    timeout: 10000 // Timeout after 10000ms
};

        try {
            const response = await axios.post(`https://userapi.trialtest.ai/wp-json/wp/v2/users/${userId}`, JSON.stringify(profileData), config);
            //console.log('Update Success:', response.data);
            //console.log('Edit Profile Response:', response.data);
            // Implement any success logic needed
        } catch (error) {

            //console.error('Error submitting profile data:', error);
            // Implement error handling logic
        }
    };

    if (!user) return <div>Loading...</div>;

    return (
        <React.Fragment>
            <SiteNavbar Home={appHome} />
            <div className="mainWrapper">
                <div className="loginForm registrationForm">
                    <h2 className="loginFormHeadline">Edit Profile</h2>
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" name="id" value={userId} />
                        <div className="profileDetails">
                            
    <div className="formContainer">
       {/* <label className="label text-uppercase">Profile Image</label>
        

        
        <input 
            className="form-control" 
            type="file" 
            name="avatar" 
            onChange={handleImageChange} 
        />
    */}
        <img src={profileData.avatar} className="profileImage" alt={user.user_data.display_name} />
    </div>
<div className="formContainer">
                                <label className="label text-uppercase">Username</label>
                                <input className="form-control" type="text" name="username" value={profileData.username} onChange={handleChange} />
                            </div>
                            <div className="formContainer">
                                <label className="label text-uppercase">First Name</label>
                                <input className="form-control" type="text" name="first_name" value={profileData.first_name} onChange={handleChange} />
                            </div>
                            <div className="formContainer">
                                <label className="label text-uppercase">Last Name </label>
                                <input className="form-control" type="text" name="last_name" value={profileData.last_name} onChange={handleChange} />
                            </div>
                            <div className="formContainer">
                                <label className="label text-uppercase">Display Name </label>
                                <input className="form-control" type="text" name="display_name" value={profileData.display_name} onChange={handleChange} />
                            </div>
                            <div className="formContainer">
                                <label className="label text-uppercase">Email </label>
                                <input className="form-control" type="email" name="email" value={profileData.email} onChange={handleChange} />
                            </div>

                            <div className="formContainer">
                                <label className="label text-uppercase">Nickname </label>
                                <input className="form-control" type="text" name="nickname" value={profileData.nickname} onChange={handleChange} />
                            </div>

                            <div className="formContainer">
                                <label className="label text-uppercase">Website </label>
                                <input className="form-control" type="text" name="url" value={profileData.url} onChange={handleChange} />
                            </div>


                            <div className="formContainer">
                                <label className="label text-uppercase">Bio </label>
                               <textarea 
                                    className="form-control" 
                                    name="description" 
                                    value={profileData.bio} 
                                    onChange={handleChange} 
                                    rows="3" // You can adjust the number of rows as needed
                                />

                            </div>
                            <button className="btn btn-secondary" type="submit">Save Changes</button>
                        </div>
                    </form>
                </div>
            </div>
            <SiteFooter CopyRight={appCopyright} />
        </React.Fragment>
    );
};

export default EditProfile;
import React, { useEffect, useState } from 'react';
import { Grid, Button, Typography, ThemeProvider, createTheme } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DoubleArrowIcon from '@material-ui/icons/NavigateNext';
import KeyboardDoubleArrowLeftIcon from '@material-ui/icons/NavigateBefore';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import FlagIcon from '@material-ui/icons/Flag';
import ReplayIcon from '@material-ui/icons/Replay';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { Box } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { useParams, useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import HelpIcon from '@material-ui/icons/Help';
import FormControl from '@material-ui/core/FormControl';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import axios from 'axios';
import Question from './components/Question';
import SiteNavbar from './components/SiteHeader';
import SiteFooter from './components/SiteFooter';
import PreventCopyPaste from './components/PreventCopyPaste';
import PreventReloadComponent from './components/PreventReloadComponent';
import QuizContext from './context/QuizContext';
import { Modal, Paper } from '@material-ui/core';
import './assets/styles/styles.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useLocation } from 'react-router-dom';

const questionsUrl=process.env.REACT_APP_QUESTIONS_URL;
const examsUrl=process.env.REACT_APP_EXAMS_URL;
const importAll = (requireContext) => {
  return requireContext.keys().reduce((images, next) => {
    images[next.replace('./', '')] = requireContext(next).default;
    return images;
  }, {});
};

const images = importAll(require.context('./assets/images', false, /\.(png|jpe?g|svg)$/));

const ExamLive = () => {
  const history = useHistory();
  const [question, setQuestion] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [answerArray, setAnswerArray] = useState([]);
  const [totalTime, setTotalTime] = useState(0);
  const [currentQuestionId, setCurrentQuestionId] = useState(); // the first ID
  const [examSummaryData, setExamSummaryData] = useState(null);
  const [flagged, setFlagged] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentURL = window.location.pathname;
  const urlParts = currentURL.split('/');
  const examIdUniqueID = urlParts[3];
  const examSetID = urlParts[4];
  const [questionIdList, setQuestionIdList] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const location = useLocation();
  const [_, __, technology, examLevel] = location.pathname.split('/');
  const [totalQuestions, settotalQuestions] = useState(false);
  
  //console.log(examSetID);

   useEffect(() => {
    fetchQuestion();
  }, [currentQuestionIndex]);

const fetchQuestion = async () => {
  try {
    const response = await axios.get(`${examsUrl}live.php?baseExamId=${examIdUniqueID}`);
    const data = response.data[0];
    
    
    setQuestionIdList(data.questions);

    const currentQuestionId = data.questions[currentQuestionIndex];
    //console.log(currentQuestionId);

    const questionData = await getQuestionData(currentQuestionId);
    
    setQuestion(questionData);
    settotalQuestions(data.totalQuestions);


    
    const examSummaryData = data;
    setExamSummaryData(examSummaryData);
     setCurrentQuestionId(currentQuestionId);

     //console.log(examSummaryData);
    

    const answerData = data.answers.find(answer => answer.id === currentQuestionId);
    if (answerData) {
      const selectedOption = answerData.selected;
      setSelectedOption(selectedOption);
      const isFlagged = answerData.flagged;
      setFlagged(isFlagged);
      
    }
  } catch (error) {
    console.error('Error fetching question:', error);
  }
};


   const getQuestionData = async (id) => {
    try {
      const response = await axios.get(`${examsUrl}question.php?examId=${examIdUniqueID}&question_id=${id}`);

      return response.data;

    } catch (error) {
      console.error('Error fetching question data:', error);
    }
  }


const ProgressComponent = () => {
  if (!examSummaryData) {
    return null;
  }

  console.log(examSummaryData);
  const counter = `${currentQuestionIndex+1}/${examSummaryData.ExamQuestions}`;

  return <div>Progress: {counter}</div>;
};


const RemainingTimeComponent = () => {
  if (!examSummaryData || !examSummaryData.examEndTime) {
    return null;
  }

  const examStart = examSummaryData.examStart;
  const examEnd = examSummaryData.examEndTime;

  const [remainingTime, setRemainingTime] = useState(getRemainingTime());

  function getRemainingTime() {
    const currentTime = Math.floor(Date.now() / 1000);
    return Math.max(0, examEnd - currentTime);
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(getRemainingTime());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const remainingTimeString = formatTime(remainingTime);

  return <div>Remaining Time: {remainingTimeString}</div>;
};





  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

const handleNextClick = async () => {
    
     if (currentQuestionIndex === questionIdList.length - 1) {
      const path = `/exam/${technology}/${examIdUniqueID}/${examSetID}/summary`;
      history.push(path);
    } 


    

  const timeTaken = calculateTimeTaken();
  const answer = {
    id: currentQuestionId,
    select: selectedOption,
    timetaken: timeTaken,
    flagged: flagged, // Set the flagged value based on the flagged state
  };

  const updatedAnswerArray = [...answerArray, answer];
  setAnswerArray(updatedAnswerArray);

  if (currentQuestionId === 1) {
    setTotalTime(timeTaken);
  }

  setCurrentQuestionIndex(currentQuestionIndex + 1);
  setSelectedOption('');

  const answerData = {
    examID: examIdUniqueID,
    answers: updatedAnswerArray.map((answer) => ({
      id: answer.id,
      selected: answer.select,
      timetaken: answer.timetaken,
      flagged: answer.flagged,
    })),
  };

  try {
    await axios.post(`${examsUrl}live.php?examId=${answerData.examID}&question_id=${answer.id}&selectOption=${answer.select}&flagOption=${answer.flagged}`, answerData, {
  headers: {
    'Content-Type': 'application/json',
  },
  data: answerData,
});

  } catch (error) {
    console.error('Error sending answer data:', error);
  }
};


const handlePreviousClick = async () => {
  const timeTaken = calculateTimeTaken();
  const answer = {
    questionid: currentQuestionId,
    select: selectedOption,
    timetaken: timeTaken,
    flagged: false,
  };

  const updatedAnswerArray = [...answerArray, answer];
  setAnswerArray(updatedAnswerArray);

  setCurrentQuestionIndex(currentQuestionIndex - 1);
  setSelectedOption('');

  const answerData = {
    examID: examIdUniqueID,
    answers: updatedAnswerArray.map((answer) => ({
      id: answer.id,
      selected: answer.select,
      timetaken: answer.timetaken,
      flagged: answer.flagged,
    })),
  };
  try { 
        await axios.post(`${examsUrl}?live.php&examId=${answerData.examID}&question_id=${answer.id}&selectOption=${answer.select}&flagOption=${answer.flagged}`, answerData, {
  headers: {
    'Content-Type': 'application/json',
  },
  data: answerData,
});

  } catch (error) {
    console.error('Error sending answer data:', error);
  }
};


  const calculateTimeTaken = () => {
    // Calculate time taken for the question (You can implement your own logic here)
    return 120;
  };

  const handleOpenModal = () => {
  setIsModalOpen(true);
};
const handleCloseModal = () => {
  setIsModalOpen(false);
};

 //console.log(question);
  if (!question) {
    return <p>Loading...</p>;
  }

  const renderAnswerText = (text) => {
    return text.includes('<') ? text : ReactHtmlParser(text);
  };

  return (  
      <div className="Questions">
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} md={8} className="Grid-Item">
          <FormControl component="fieldset" className="Full-Width">
            
            <Typography variant="h4" color="inherit" align="center" onClick={handleOpenModal} className="Text-Color questionHeadline">
              {currentQuestionIndex+1}. {ReactHtmlParser(question.question_text)}
                            {question.answerHint !== "" && <HelpIcon />}

            </Typography>
            <h3 className="questionCounterText">Question {currentQuestionIndex+1}/{totalQuestions}</h3>
            {question.question_code && question.question_code.length > 0 &&  (
              <div className="Code-Block codeWindow">
                <SyntaxHighlighter showLineNumbers language="javascript" style={darcula}>
                  {question.question_code.join('\n')}
                </SyntaxHighlighter>
              </div>
            )}
            {question.image && <img className="Image" alt="" src={images[`${question.image}`]} />}
            <div>
             <RadioGroup 
  className="questionOptions" 
  aria-label="answer" 
  name="answers" 
  value={selectedOption} 
  onChange={handleOptionChange}
>
  {question && question.answers && 
    Object.entries(question.answers).map(([option, text]) => (
      <FormControlLabel
        key={option}
        value={option}
        control={<Radio />}
        label={text}
        className="optionLevel"
      />
    ))
  }
</RadioGroup>

            </div>
            <Grid container justifyContent="center" className="examNavButtons">
              {currentQuestionId !== 1 && (
                <Button
                  className="Space-Bottom primaryButtons"
                  variant="contained"
                  onClick={handlePreviousClick}
                  color="primary"
                  size="large"
                  disableElevation
                  startIcon={<KeyboardDoubleArrowLeftIcon />}
                  disabled={currentQuestionIndex === 0}
                >
                  Previous
                </Button>
              )}
              <Button
                className={`Space-Bottom ${flagged ? 'flaggedButton' : ''}`}
                variant="contained"
                onClick={() => setFlagged(!flagged)}
                color="primary"
                size="large"
                disableElevation
                startIcon={<FlagIcon />}
              >
                {flagged ? 'Flagged' : 'Flag'}
              </Button>
              <Button
                className="Space-Bottom"
                variant="contained"
                onClick={handleNextClick}
                color="primary"
                size="large"
                disableElevation
                endIcon={<DoubleArrowIcon />}
              >
                {currentQuestionIndex+1 === totalQuestions ? 'Summary' : 'Next'}
              </Button>
            </Grid>
          </FormControl>
          <Modal open={isModalOpen} onClose={handleCloseModal}>
            <div className="detailModal">
              <header className="modalHeader">
                <button className="closeButton" onClick={handleCloseModal}>
                  <CloseIcon />
                </button>
              </header>
              <div className="modalBody">
                <Paper>
                  <Typography variant="h6" className="modalQuestionDetails">Question Details</Typography>
                  <Typography className="modalQuestionTitle">{question.question_text}</Typography>
                  <Typography className="modalQuestionAnswerHint">{question.answerHint}</Typography>
                </Paper>
              </div>
            </div>
          </Modal>
        </Grid>
      </Grid>
    </div>
);
};

export default ExamLive;
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomePage from './HomePage';
import DetailsPage from './DetailsPage';
import ExamsListPage from './ExamsListPage';
import ExamDetailsPage from './ExamDetailsPage';
import ExamIntro from './ExamIntro';
import ExamLive from './ExamLive';
import ExamSummary from './ExamSummary';
import ExamResult from './ExamResult';
import Register from './components/users/Register';
import Login from './components/users/Login';
import Logout from './components/users/Logout';
import Passreset from './components/users/Passreset';
import Profile from './components/users/Profile';
import EditProfile from './components/users/EditProfile';
import './assets/styles/styles.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        {/* Update these routes as needed */}
        <Route path="/details/:subject/:entryId" component={DetailsPage} />
        <Route path="/examslist/:examSubject/:examSubjectId" component={ExamsListPage} />
        <Route path="/exam/details/:examSubject/:examID/:examSetId" component={ExamDetailsPage} />
        <Route path="/exam/:examSubject/:examId/:examSetId/intro" component={ExamIntro} />
        <Route path="/exam/:examSubject/:examId/:examSetId/live" component={ExamLive} />
        <Route path="/exam/:examSubject/:examId/:examSetId/summary" component={ExamSummary} />
        <Route path="/exam/:examSubject/:examId/:examSetId/result" component={ExamResult} />

        {/* New Routes */}
        <Route path="/register" component={Register} />
        <Route path="/login" component={Login} />
        <Route path="/logout" component={Logout} />
        <Route path="/passreset" component={Passreset} />
        <Route path="/profile/edit/:userId" component={EditProfile} />
        <Route path="/profile/:userId" component={Profile} />
      </Switch>
    </Router>
  );
}

export default App;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@material-ui/icons/Info';
import DoubleArrowIcon from '@material-ui/icons/NavigateNext';
import DoubleArrowIconPrevious from '@material-ui/icons/NavigateBefore';
import SchoolIcon from '@material-ui/icons/School';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { fetchSiteData } from './SiteData';

/* Envirnental variables */
const productUrl = process.env.REACT_APP_PRODUCT_URL;

const WelcomePageComponent = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchCat, setSearchCat] = useState([]);
  const [searchLevel, setSearchLevel] = useState('');
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(true);
  const [showExams, setShowExams] = useState(false);
  const [expandedItemId, setExpandedItemId] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedLang = searchParams.get('lang') || 'en';

  const [siteData, setSiteData] = useState({});
    const [appLearnText, setappLearnText] = useState('');
    const [appTopicText, setappTopicText] = useState('');
    const [appTagsText, setappTagsText] = useState('');
    const [appExamStartText, setappExamStartText] = useState('');
    const [appExamFreeText, setappExamFreeText] = useState('');
    const [appNextText, setappNextText] = useState('');
    const [appPreviousText, setappPreviousText] = useState('');
    const [appNoExamText, setappNoExamText] = useState('');

useEffect(() => {
    fetchData();
  }, [currentPage, searchTerm, searchCat, selectedLang]);

useEffect(() => {
    setIsSearchButtonDisabled(!searchTerm && searchCat.length === 0);
}, [searchTerm, searchCat]);

const fetchData = async () => {
  try {
    const url = `${productUrl}?pageID=${currentPage}&searchTerm=${searchTerm}&searchCat=${searchCat.join(',')}&lang=${selectedLang}`;
    const response = await axios.get(url);
    setData(response.data);
    setLoading(false);
    //console.log(response.data);

 const fetchedData = await fetchSiteData(/* provide any required parameters here, if needed */);
                setSiteData(fetchedData); // Step 2: Fetch site data and set it in state
        

           setappLearnText(fetchedData.projectInfoGeneral.APP_EXAM_LEARN);
           setappTopicText(fetchedData.projectInfoGeneral.APP_EXAM_TOPIC);
           setappTagsText(fetchedData.projectInfoGeneral.APP_EXAM_TAGS);
           setappExamStartText(fetchedData.projectInfoGeneral.APP_EXAM_START);
           setappExamFreeText(fetchedData.projectInfoGeneral.APP_EXAM_FREE);
           setappNextText(fetchedData.projectInfoGeneral.APP_PAGE_NEXT);
           setappPreviousText(fetchedData.projectInfoGeneral.APP_PAGE_PREVIOUS);
           setappNoExamText(fetchedData.projectInfoGeneral.APP_EXAM_EMPTY);
           

  } catch (error) {
    console.error('Error fetching data:', error);
    setLoading(false);
  }
};


   const handleToggleExams = () => {
    setShowExams(!showExams);
  };
 
  const handleToggleItem = (itemId) => {
    setExpandedItemId(itemId === expandedItemId ? null : itemId);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchLevelChange = (event) => {
    setSearchLevel(event.target.value);
  };

    const handleSearchCatChange = (event) => {
    setSearchCat(event.target.value);
  };

  const handleSearch = () => {
    if (searchTerm || searchCat) {
      fetchData();
    }
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  const renderList = () => {

if (loading) {
  
  return (
    <div className="loadingContainer">
      <CircularProgress />
    </div>
  );
}

    if (data && data.Entries && data.Entries.length > 0) {
      return data.Entries.map((entry) => (
        <li key={entry.id} className="mainListLi">
            <a href={`/details/${entry.uri}/${entry.subjectCode}?lang=${selectedLang}`} target="_blank" ><img className="technologyLogo" src={entry.logo} alt={entry.name} /> </a>
            <div className="practiceExamLink">
            <a href={`/details/${entry.uri}/${entry.subjectCode}?lang=${selectedLang}`} target="_blank" ><h1 className="technologyHeadline" >{entry.title.split(' - ')[1]}</h1> </a>
            <p className="technologyShortDescription">{entry.shortDescription}</p>
 
             <div className="examList">
             <a href={`/details/${entry.uri}/${entry.subjectCode}?lang=${selectedLang}`}  ><h5 className="freeExamsHeadline" >
                
                {appLearnText} {<InfoIcon />}
              </h5></a>
              <a href={`/examslist/${entry.uri}/${entry.subjectCode}?lang=${selectedLang}`}  ><h5 className="freeExamsHeadline" >
                
                {appExamFreeText} {<DoubleArrowIcon />}
              </h5></a>
              {expandedItemId === entry.id && (
                <div className="examListLink">
                  {entry.freeExams.map((exam) => (
                    <a href={`/exam/${entry.name}/${exam.examID}/intro?lang=${selectedLang}`} target="_blank" >
                      <SchoolIcon /> {exam.examTitle} (20Q)
                    </a>
                  ))}
                </div>
              )}
            </div>
          
          </div>
        </li>
      ));
    }

    return  <p className="noExamFound">{appNoExamText}</p>;
  };

  const renderPagination = () => {
    if (loading || !data.Summary || data.Summary.Total === 0) {
      return null;
    }

    const totalPages = Math.ceil(data.Summary.Total / data.Summary.PerPage);

    if (totalPages <= 1) {
      return null;
    }

    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

    return (
      <div className="paginationWrapper">
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
           className={`paginationButton ${currentPage === 1 ? 'disabled' : ''}`}
        >
          <DoubleArrowIconPrevious /> Previous
        </button>
        {pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            className={`paginationButton ${currentPage === pageNumber ? 'active' : ''}`}
            onClick={() => handlePageChange(pageNumber)}
          >
            {pageNumber}
          </button>
        ))}
        <button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
          className={`paginationButton ${currentPage === totalPages ? 'disabled' : ''}`}

        >
          Next <DoubleArrowIcon />
        </button>
      </div>
    );
  };

  useEffect(() => {
    setIsSearchButtonDisabled(!(searchTerm || searchCat));
  }, [searchTerm, searchCat]);

  return (
    <div className="technologyNamesWrapper">
    {data.Summary && data.Summary.Total >= data.Summary.PerPage && (
      <div className="searchInputWrapper ">
      <div className="search-container">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchTermChange}
          placeholder={props.searchTermText}
          onKeyDown={handleSearchKeyPress}
          className="searchButton"
        />

        <Select 
          isMulti
          value={data.Summary && data.Summary.categories ? Object.entries(data.Summary.categories).filter(([key, _]) => searchCat.includes(key)).map(([key, value]) => ({ value: key, label: value })) : []}
          onChange={options => setSearchCat(options ? options.map(option => option.value) : [])}
          options={data.Summary && data.Summary.categories ? Object.entries(data.Summary.categories).map(([key, value]) => ({ value: key, label: value })) : []}
          placeholder={props.searchCatText}
          classNamePrefix="select"
      />


        <button onClick={handleSearch} disabled={isSearchButtonDisabled || loading} className="btn btn-secondary searchBtnText">
        <SearchIcon />
        </button>
      </div>
      </div>
    )}

      <ul className="container itemListWrapper">
      {renderList()}
      </ul>
      {renderPagination()}
    </div>
  );
};

export default WelcomePageComponent;

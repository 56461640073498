import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SiteNavbar from './components/SiteHeader';
import WelcomePageComponent from './components/examListBuilder';
import SiteFooter from './components/SiteFooter';
import { fetchSiteData } from './components/SiteData';
import PreventCopyPaste from './components/PreventCopyPaste';

const examDataUrl = process.env.REACT_APP_EXAMS_URL; 

const fetchExamData = async (examSubject, lang) => {
  try {
    const response = await axios.get(`${examDataUrl}dexams.php?examSubject=${examSubject}&lang=${lang}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const useStyles = makeStyles((theme) => ({
  technologyNamesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30vh',
    [theme.breakpoints.down('sm')]: {
      height: '50vh',
    },
  },
  siteHeadlineText: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  siteMainDetails: {
    textAlign: 'justify',
    maxWidth: '80%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      padding: theme.spacing(0, 2),
    },
  },
}));

function ExamsListPage() {
  const { examSubject, examSubjectId } = useParams();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [appTitle, setAppTitle] = useState('');
  const [appShortDesc, setappShortDesc] = useState('');
    const [appCopyright, setappCopyright] = useState('');
    const [appHome, setappHome] = useState('');
    const [appSearchTerms, setappSearchTerms] = useState('');
    const [appSearchCatText, setappSearchCatText] = useState('');
    const [appLevelText, setappLevelText] = useState('');
    const [appTopicText, setappTopicText] = useState('');
    const [appTagsText, setappTagsText] = useState('');
    const [appExamStartText, setappExamStartText] = useState('');
    const [appNextText, setappNextText] = useState('');
    const [appPreviousText, setappPreviousText] = useState('');
    const [appNoExamText, setappNoExamText] = useState('');

  //console.log(examSubject);

  useEffect(() => {
    const fetchDataFromAPI = async () => {
      const queryParams = new URLSearchParams(location.search);
      const lang = queryParams.get('lang') || 'en';

      try {
        const examAPIData = await fetchExamData(examSubject, lang);
        setData(examAPIData);

        //console.log(examAPIData);

        if (examAPIData) {
          setAppTitle(examAPIData.examPageHeadline);
          setappShortDesc(examAPIData.examPageParagraph);
        }

        const siteData = await fetchSiteData(examSubject, lang);
        setData(siteData);

        if (siteData) {
         
          if (siteData.projectInfo) { // Step 2: Set the state with fetched data
            
           setappCopyright(siteData.projectInfo.APP_COPYRIGHT);
           setappHome(siteData.projectPages.home.menu_text);
           setappSearchCatText(siteData.projectInfoGeneral.APP_SEARCH_CATEGORIES);
           setappSearchTerms(siteData.projectInfoGeneral.APP_SEARCH);

           setappLevelText(siteData.projectInfoGeneral.APP_EXAM_LEVEL);
           setappTopicText(siteData.projectInfoGeneral.APP_EXAM_TOPIC);
           setappTagsText(siteData.projectInfoGeneral.APP_EXAM_TAGS);
           setappExamStartText(siteData.projectInfoGeneral.APP_EXAM_START);
           setappNextText(siteData.projectInfoGeneral.APP_PAGE_NEXT);
           setappPreviousText(siteData.projectInfoGeneral.APP_PAGE_PREVIOUS);
           setappNoExamText(siteData.projectInfoGeneral.APP_EXAM_EMPTY);

          }
        }

      } catch (error) {
        // Handle the error
      }
    };

    fetchDataFromAPI();
  }, [examSubject, location.search]);


  const classes = useStyles();

  return (
    <React.Fragment>
      <PreventCopyPaste />
     <SiteNavbar  Home={appHome}/>
      <div className={`${classes.technologyNamesWrapper} welcomeWrapper`}>
        <Typography
          variant="h2"
          component="h1"
          className="examsHeadlineText"
          align="center"
          gutterBottom
        >
          {appTitle}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          className={classes.siteMainDetails}
          align="center"
        >
          {appShortDesc}
        </Typography>
      </div>
      <WelcomePageComponent />
      <SiteFooter CopyRight={appCopyright} />  {/* Step 3: Pass the prop to SiteFooter */}
    </React.Fragment>
  );
}

export default ExamsListPage;
import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import SiteNavbar from './components/SiteHeader';
import SiteFooter from './components/SiteFooter';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import HomeIcon from '@material-ui/icons/Home';
import TwitterIcon from '@material-ui/icons/Twitter';
import GithubIcon from '@material-ui/icons/GitHub';
import YoutubeIcon from '@material-ui/icons/YouTube';
import WebsiteIcon from '@material-ui/icons/ImportantDevices';
import SchoolIcon from '@material-ui/icons/School';
import TechStack from '@material-ui/icons/Code';
import TagIcon from '@material-ui/icons/Style';
import TopicIcon from '@material-ui/icons/CastForEducation';
import TrainingIcon from '@material-ui/icons/School';
import LearningIcon from '@material-ui/icons/LocalLibrary';
import WebsitesIcon from '@material-ui/icons/Language';
import ReditIcon from '@material-ui/icons/Reddit';
import DiscussionIcon from '@material-ui/icons/Chat';
import { fetchSiteData } from './components/SiteData';
import DisqusComments from './components/DisqusComments';
const DetailsPage = () => {
const [data, setData] = useState({});
const productUrl = process.env.REACT_APP_PRODUCT_URL;
const [loading, setLoading] = useState(true);

const [siteData, setSiteData] = useState({});
    const [appHome, setappHome] = useState('');
    const [appLevelText, setappLevelText] = useState('');
    const [appCopyright, setappCopyright] = useState('');
    const [appTopicText, setappTopicText] = useState('');
    const [appTagsText, setappTagsText] = useState('');
    const [appExamStartText, setappExamStartText] = useState('');
    const [appNextText, setappNextText] = useState('');
    const [appPreviousText, setappPreviousText] = useState('');
    const [appNoExamText, setappNoExamText] = useState('');
    const { subject, entryId } = useParams();
     // Extract the query string from the current URL
  const queryString = window.location.search;

  // Use URLSearchParams to parse the query string
  const urlParams = new URLSearchParams(queryString);

  // Get the value of the 'lang' parameter
  const langValue = urlParams.get('lang');




{/* console.log(entryId); */ }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const url = `${productUrl}?objectID=${entryId}`;
      const response = await axios.get(url);
      setData(response.data);
      setLoading(false);


       const fetchedData = await fetchSiteData(/* provide any required parameters here, if needed */);
           setSiteData(fetchedData); // Step 2: Fetch site data and set it in state
           {/* console.log(siteData); */}
           setappCopyright(fetchedData.projectInfo.APP_COPYRIGHT);
           setappLevelText(fetchedData.projectInfoGeneral.APP_EXAM_LEVEL);
           setappTopicText(fetchedData.projectInfoGeneral.APP_EXAM_TOPIC);
           setappTagsText(fetchedData.projectInfoGeneral.APP_EXAM_TAGS);
           setappExamStartText(fetchedData.projectInfoGeneral.APP_EXAM_START);
           setappNextText(fetchedData.projectInfoGeneral.APP_PAGE_NEXT);
           setappPreviousText(fetchedData.projectInfoGeneral.APP_PAGE_PREVIOUS);
           setappNoExamText(fetchedData.projectInfoGeneral.APP_EXAM_EMPTY);
           setappHome(fetchedData.projectPages.home.menu_text);
           {/* console.log(appHome); */}


    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
  <React.Fragment>
       <SiteNavbar  Home={appHome}/>
    <div className="container itemDetails">
      
      <div className="detailsText">
      <img src={data.logo} alt={data.name} />
      <h2 className="detailsHeadline">{data.title}</h2>
      <p className="detailsDescription">{data.longDescriptionIntro}</p>
      <h2 className="detailsHeadline">{data.LongDescriptionHeadlineOne}</h2>
      <p className="detailsDescription">{data.LongDescriptionParagraphOne}</p>
      <h2 className="detailsHeadline">{data.LongDescriptionHeadlineTwo}</h2>
      <p className="detailsDescription">{data.LongDescriptionParagraphTwo}</p>
      <h2 className="detailsHeadline">{data.LongDescriptionHeadlineThree}</h2>
      <p className="detailsDescription">{data.LongDescriptionParagraphThree}</p>
      <h2 className="detailsHeadline">{data.LongDescriptionHeadlineFour}</h2>
      <p className="detailsDescription">{data.LongDescriptionParagraphFour}</p>
      <h2 className="detailsHeadline">{data.LongDescriptionHeadlineFive}</h2>
      <p className="detailsDescription">{data.LongDescriptionParagraphFive}</p>

      <p className="detailsDescription">{data.LongDescriptionConclusion}</p>
     
{
  data.learningTopics && data.learningTopics.length > 0 && (
    <>
     <h2 className="detailsHeadline"><LearningIcon /> Learning Topics </h2>
      {data.learningTopics.map((item, index) => (
         <li key={index} className="detailsListing">
            <a className="detailsLink" href="#" target="_blank" >{item}</a>
          </li>
      ))}
    </>
  )
}


{
  data.TopTwitter && data.TopTwitter.length > 0 && (
    <>
       <h2 className="detailsHeadline"> <TwitterIcon /> Top Twitter Handles</h2>
      {data.TopTwitter.map((item, index) => (
         <li key={index} className="detailsListing">
            <a className="detailsLink" href={item.link} target="_blank" >{item.name}</a>
          </li>
      ))}
    </>
  )
}

{
  data.TopYoutube && data.TopYoutube.length > 0 && (
    <>
        <h2 className="detailsHeadline"> <YoutubeIcon /> Top Youtube Channels</h2>
      {data.TopYoutube.map((item, index) => (
         <li key={index} className="detailsListing">
            <a className="detailsLink" href={item.link} target="_blank" >{item.name}</a>
          </li>
      ))}
    </>
  )
}

{
  data.TopSites && data.TopSites.length > 0 && (
    <>
    <h2 className="detailsHeadline"><WebsitesIcon /> Top Sites </h2>
      {data.TopSites.map((item, index) => (
         <li key={index} className="detailsListing">
            <a className="detailsLink" href={item.link} target="_blank" >{item.name}</a>
          </li>
      ))}
    </>
  )
}

{
  data.TopRedit && data.TopRedit.length > 0 && (
    <>
    <h2 className="detailsHeadline"> <ReditIcon /> Top Redit Channels</h2>
      {data.TopRedit.map((item, index) => (
         <li key={index} className="detailsListing">
            <a className="detailsLink" href={item.website} target="_blank" >{item.name}</a>
          </li>
      ))}
    </>
  )
}

{
  data.TopStackflow && data.TopStackflow.length > 0 && (
    <>
     <h2 className="detailsHeadline"> <DiscussionIcon /> Top Stackflow Channels</h2>
      {data.TopStackflow.map((item, index) => (
         <li key={index} className="detailsListing">
            <a className="detailsLink" href={item.website} target="_blank" >{item.name}</a>
          </li>
      ))}
    </>
  )
}

{
  data.TopPaidTrainingSites && data.TopPaidTrainingSites.length > 0 && (
    <>
    <h2 className="detailsHeadline"> <TrainingIcon /> Top Piad Training Sites </h2>
      {data.TopPaidTrainingSites.map((item, index) => (
         <li key={index} className="detailsListing">
            <a className="detailsLink" href={item.website} target="_blank" >{item.name}</a>
          </li>
      ))}
    </>
  )
}

{
  data.TopFreeTrainingSites && data.TopFreeTrainingSites.length > 0 && (
    <>
     <h2 className="detailsHeadline"> <TopicIcon /> Top Free Training Sites </h2>
      {data.TopFreeTrainingSites.map((item, index) => (
         <li key={index} className="detailsListing">
            <a className="detailsLink" href={item.website} target="_blank" >{item.name}</a>
          </li>
      ))}
    </>
  )
}

{
  data.tags && data.tags.length > 0 && (
    <>
      <h2 className="detailsHeadline"> <TagIcon /> Tags </h2>
      {data.tags.map((item, index) => (
         <li key={index} className="detailsListing">
            <a className="detailsLink" href="#" target="_blank" >{item}</a>,
          </li>
      ))}
    </>
  )
}

{
  data.NodejsLibraries && data.NodejsLibraries.length > 0 && (
    <>
      <h2 className="detailsHeadline"> <TagIcon /> Top Libraries </h2>
      {data.NodejsLibraries.map((item, index) => (
         <li key={index} className="detailsListing">
            <a className="detailsLink" href={item.npmLink} target="_blank" >{item.name}</a>,
          </li>
      ))}
    </>
  )
}

{
  data.techStack && data.techStack.length > 0 && (
    <>
       <h2 className="detailsHeadline"><TechStack /> TechStack </h2>
      {data.techStack.map((item, index) => (
         <li key={index} className="detailsListing">
            <a className="detailsLink" href="#" target="_blank" >{item},</a>
          </li>
      ))}
    </>
  )
}

{
  data.TopGithub && data.TopGithub.length > 0 && (
    <>
        <h2 className="detailsHeadline"><GithubIcon />Top Github </h2>
      {data.TopGithub.map((item, index) => (
        <li key={index} className="detailsListing">
            <a className="detailsLink" href={item.link} target="_blank" >{item.name}</a>
          </li>
      ))}
    </>
  )
}
{/*
{
  data.freeExams && data.freeExams.length > 0 && (
    <>
      <h2 className="detailsHeadline"><SchoolIcon /> Free  Exams </h2>
      {data.freeExams.map((item, index) => (
        <li key={index} className="detailsListing">
          <a className="detailsLink" href={`/exam/${data.name.toLowerCase()}/${item.examID}/intro`} target="_blank">{item.examTitle} <LockOpenIcon /></a>
        </li>
      ))}
    </>
  )
}

 {
  data.paidExams && data.paidExams.length > 0 && (
    <>
      <h2 className="detailsHeadline"><SchoolIcon /> Paid Exams </h2>
      {data.paidExams.map((item, index) => (
        <li key={index} className="detailsListing">
          <a className="detailsLink" href={`/exam/${data.name.toLowerCase()}/${item.examID}/intro`} target="_blank">{item.examTitle} <LockIcon /></a>
        </li>
      ))}
    </>
  )
}
*/}

    <h2 className="detailsHeadline"><SchoolIcon /> <a className="detailsLink" href={`/examslist/${data.name}/${data.subjectCode}?lang=${langValue}`} target="_blank">   Free  Exams </a></h2>

      <a href="/" className="backHome"> <HomeIcon /> Back to Home </a>
      <DisqusComments />
    </div>


    </div>
     <SiteFooter CopyRight={appCopyright} />  {/* Step 3: Pass the prop to SiteFooter */}
    </React.Fragment>
  );
};

export default DetailsPage;
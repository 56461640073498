import React, { useReducer, useEffect, useState } from 'react';
import { Grid, Button, Typography, ThemeProvider, createTheme } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DoubleArrowIcon from '@material-ui/icons/NavigateNext';
import KeyboardDoubleArrowLeftIcon from '@material-ui/icons/NavigateBefore';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import FlagIcon from '@material-ui/icons/Flag';
import ReplayIcon from '@material-ui/icons/Replay';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { Box } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import Question from './components/Question';
import SiteNavbar from './components/SiteHeader';
import SiteFooter from './components/SiteFooter';
import PreventCopyPaste from './components/PreventCopyPaste';
import PreventReloadComponent from './components/PreventReloadComponent';
import QuizContext from './context/QuizContext';
import './assets/styles/styles.css';

const questionsUrl=process.env.REACT_APP_QUESTIONS_URL;
const examsUrl=process.env.REACT_APP_EXAMS_URL;


function ExamSummary(props) {
  const history = useHistory();
  const { examSubject, examId, examSetId } = useParams();
  const [loading, setLoading] = useState(true);
  const [examIdUniqueID, setexamIdUniqueID] = useState(null);
  const [technology, settechnology] = useState(null);
  const [examData, setExamData] = useState(null);
  // additional state for exam metrics
  const [examMetrics, setExamMetrics] = useState({
    totalAnswered: 0,
    totalNotAnswered: 0,
    totalFlagged: 0,
    remainingTime: 0,
    allocatedTime: 0
  });


  useEffect(() => {
    fetchExamData();
  }, []);


const handleSubmitClick = async () => {
      const path = `/exam/${examSubject}/${examId}/${examSetId}/result`;
      history.push(path);

  }

  const handleBackToExam = async () => {
      const path = `/exam/${examSubject}/${examId}/${examSetId}/live`;
      history.push(path);

  }

  const fetchExamData = async () => {
  try {
    const response = await axios.get(`${examsUrl}summary.php?examId=${examId}&examSetId=${examSetId}`);
    const data = response.data;
    console.log(data);
    setExamData(data);
    setexamIdUniqueID(data.examId);
    settechnology(examSubject);

    // calculating exam metrics
    const totalAnswered = data.totalQuestions  - data.notAnsweredCount;
    const totalNotAnswered = data.notAnsweredCount || 0;
    const totalFlagged = data.flaggedQuestionsCount || 0;
    const currentTimeStamp = Date.now() / 1000; // Get the current time in seconds
    const durationInSeconds = data.allocatedTime;
    const durationInMinutes = data.allocatedTime/60;
    const allocatedTime = data.allocatedTime/60;
    const remainingTimeInMinutes = data.remainingTime;
    console.log(data.remainingTime);
    setExamMetrics({
      totalAnswered, 
      totalNotAnswered, 
      totalFlagged, 
      remainingTime: remainingTimeInMinutes, 
      allocatedTime: Math.round(allocatedTime / 60)
    });

    setLoading(false);
  } catch (error) {
    console.error('Error fetching exam data:', error);
    setLoading(false);
  }
};

/*
*/
{/* console.log(examData); */}
  return (
    <div className="examIntroScreen">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
        <PreventReloadComponent />
          <Grid container justifyContent="center" alignItems="center" className="examIntro">
              <Box maxWidth="900px" margin="auto" className="examIntroBox">
                <Typography variant="h3" color="inherit" align="center" className="Text-Color">
                  Summary 
                </Typography>
                <Box display="flex" justifyContent="center" my={2}>
                  <Typography variant="body1" color="inherit" align="center" className="Text-Color">
                   
                        </Typography>
                </Box>
                <Box textAlign="left" my={2} className="quizFacts">
                 <Typography variant="h6" className="Text-Color">
                    <span className="quizText">Total:</span>  {examData.totalQuestions} Questions
                  </Typography>
                  <Typography variant="h6" className="Text-Color">
                    <span className="quizText">Answered:</span> {examMetrics.totalAnswered} Questions
                  </Typography>
                  <Typography variant="h6" className="Text-Color">
                    <span className="quizText">Not answered:</span> {examMetrics.totalNotAnswered} Questions
                  </Typography>
                  <Typography variant="h6" className="Text-Color">
                    <span className="quizText">Flagged Question:</span> {examMetrics.totalFlagged} Questions
                  </Typography>
                  <Typography variant="h6" className="Text-Color">
                    <span className="quizText">Allocated Time:</span> {examMetrics.allocatedTime} minutes
                  </Typography>

                   <Typography variant="h6" className="Text-Color">
                    <span className="quizText">Remaining Time:</span> {examMetrics.remainingTime} minutes
                  </Typography>
                 
                 
                 
                </Box>
                {/*
                <Box textAlign="left" my={2} >
                  <Typography variant="h6" className="quizInfo quizNote">
                    Note:
                  </Typography>
                  <Typography variant="body1" className="Text-Color">
                    {examData.ExamNote}
                  </Typography>
                </Box>
                

                <Box textAlign="left" my={2}>
                  <Typography variant="h6" className="quizInfo quizNote">
                    Disclaimer:
                  </Typography>
                  <Typography variant="body1" className="Text-Color">
                  {examData.ExamDisclaimer}
                  </Typography>
                </Box>
                */}
                <Grid container justifyContent="center" spacing={2} className="introButtonsWrapper">
                  <Grid item xs={12} sm="auto" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      className="Space-Bottom primaryButtons"
                      variant="contained" 
                      onClick={handleBackToExam}
                      color="primary"
                      size="large"
                      disableElevation
                      startIcon={<ReplayIcon />}
                      
                      style={{ whiteSpace: 'nowrap', marginRight: '10px' }}
                    >
                    Back to Exam
                    </Button>
                    <Button
                      className="Space-Bottom primaryButtons"
                      variant="contained"
                      color="primary"
                      onClick={handleSubmitClick}
                      size="large"
                      startIcon={<CheckIcon />}
                      disableElevation
                     
                      style={{ whiteSpace: 'nowrap', marginLeft: '10px' }}
                    >
                     Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
        </>
      )}
    </div>
  );
}
export default ExamSummary;
import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext'; // Adjust the path as needed
import SiteNavbar from '../SiteHeader';
import SiteFooter from '../SiteFooter';
import { fetchSiteData } from '../SiteData';
const Login = () => {
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const { login } = useContext(AuthContext);
    const history = useHistory();


    const [data, setData] = useState([]);
    const [profileData, setProfileData] = useState(null);
    const { userId } = useParams(); // Get userId from URL
    const { token } = useContext(AuthContext); // Retrieve token from AuthContext
    const { isAuthenticated, user, logout } = useContext(AuthContext);
    const [appCopyright, setappCopyright] = useState('');
    const [appHome, setappHome] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [showMessage, setShowMessage] = useState(true);
    const [showError, setShowError] = useState(true);

   const handleCloseMessage = () => {
        setShowMessage(false);
    };

    const handleCloseError = () => {
        setShowError(false);
    };

     useEffect(() => {
    const fetchDataFromAPI = async () => {
      try {
        const apiData = await fetchSiteData();
        setData(apiData);
        //console.log(apiData);


        if (apiData.projectInfo) {
           setappCopyright(apiData.projectInfo.APP_COPYRIGHT);
           setappHome(apiData.projectPages.home.menu_text);
          
        }
        
      } catch (error) {
        // Handle the error
      }
    };

    fetchDataFromAPI();
  }, []);




    const handleChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');
        setShowMessage(true);
        setShowError(true);
        try {
            const response = await axios.post('https://userapi.trialtest.ai/wp-json/jwt-auth/v1/token', credentials);
            //console.log('Login Success:', response.data);


            // Fetch user data using the token custom/v1
            const userResponse = await axios.get(`https://userapi.trialtest.ai/wp-json/custom/v1/user/${response.data.data.id}`, {
                headers: {
                    'Authorization': `Bearer ${response.data.data.token}`
                }
            });

            //console.log('User Data:', userResponse.data);
            
            // Pass the token and user data to login
            login(response.data.data.token, userResponse.data);
            setMessage('Success: ' + response.data.message);
            history.push('/');  // Redirect to the homepage

        } catch (error) {
            setError('Error: ' + error.response.data.message);
            //console.error('Login Error:', error.response ? error.response.data : error.message);
        }
    };

    return (
    <React.Fragment>
      <SiteNavbar  Home={appHome}/>
        <div className="mainWrapper">
        <div className="loginForm">
        <h2 className="loginFormHeadline"> Please Login </h2>
        {message && showMessage && (
                <div className="alert alert-success">
                <button type="button" className="close" onClick={handleCloseMessage}>
                        &times;
                    </button>
                    {message}
                    
                </div>
            )}
            {error && showError && (
                <div className="alert alert-danger">
                <button type="button" className="close" onClick={handleCloseError}>
                        &times;
                    </button>
                    {error}
                    
                </div>
            )}
        <form onSubmit={handleSubmit}>
            <div className="formContainer">
                <label className="label text-uppercase" >Username:</label>
                <input className="form-control" type="text" name="username" value={credentials.username} onChange={handleChange} required />
            </div>
            <div className="formContainer">
                <label className="label text-uppercase">Password:</label>
                <input className="form-control" type="password" name="password" value={credentials.password} onChange={handleChange} required />
            </div>
            <button className="btn btn-secondary" type="submit">Login</button>
        </form>

        <div className="quickLinks">
          <a className="quickLinkRegister" href="/register">Register</a> | 
          <a className="quickLinkPassreset" href="/passreset">Pass Reset</a>
        </div>

        </div>
        </div>
        <SiteFooter CopyRight={appCopyright} />
        </React.Fragment>
    );
};

export default Login;
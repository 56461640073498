import React from 'react';
const today = new Date();
const year = today.getFullYear();
const SiteFooter = ({ CopyRight }) => (
    <footer itemProp="hasPart" itemScope itemType="https://schema.org/WPFooter">
        <div className="container copyright pb-3">
            <div className="row">
                <div className="col-sm-6 col-md-6 mb-3 my-auto copyright-text">
                    <p itemProp="description"> {CopyRight}</p>
                </div>
                <div className="col-sm-6 col-md-6 mt-2 mt-lg-0 payment-icons">
                    <span className="ssl">
                        <img 
                            itemProp="image" 
                            src="https://skillstest.me/assets/templates/gglink/images/abstract/icons/security/ssl.png" 
                            alt="All of our traffic are secured" 
                            width="85" 
                            height="40" 
                        />
                    </span>
                    <span>
                        <img 
                            itemProp="image" 
                            src="https://skillstest.me/assets/templates/gglink/images/abstract/icons/payments/visa.png" 
                            alt="We accept all visa cards" 
                            width="35" 
                            height="26" 
                        />
                    </span>
                    <span>
                        <img 
                            itemProp="image" 
                            src="https://skillstest.me/assets/templates/gglink/images/abstract/icons/payments/master.png" 
                            alt="We accept all master cards" 
                            width="35" 
                            height="26" 
                        />
                    </span>
                    <span>
                        <img 
                            itemProp="image" 
                            src="https://skillstest.me/assets/templates/gglink/images/abstract/icons/payments/amex.png" 
                            alt="We accept Amex cards" 
                            width="35" 
                            height="26" 
                        />
                    </span>
                </div>
            </div>
        </div>
    </footer>
);

export default SiteFooter;
import React, { useReducer, useEffect, useState, useRef } from 'react';
import { Grid, Button, Typography, ThemeProvider, createTheme } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DoubleArrowIcon from '@material-ui/icons/NavigateNext';
import KeyboardDoubleArrowLeftIcon from '@material-ui/icons/NavigateBefore';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import FlagIcon from '@material-ui/icons/Flag';
import ReplayIcon from '@material-ui/icons/Replay';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Box } from '@material-ui/core';
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from 'react-html-parser';
import { useParams, useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Question from './components/Question';
import Certificate from './components/Certificate';
import SiteNavbar from './components/SiteHeader';
import SiteFooter from './components/SiteFooter';
import PreventCopyPaste from './components/PreventCopyPaste';
import PreventReloadComponent from './components/PreventReloadComponent';
import QuizContext from './context/QuizContext';
import './assets/styles/styles.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import domToImage from 'dom-to-image';
import logoImage from './assets/images/branding/logo.png';
import QRCode from 'qrcode';
/*import { generatePDF } from './components/saveResult';*/


const resultUrl=process.env.REACT_APP_RESULT_URL;
const verificationUrl=process.env.REACT_APP_VERIFICATION_URL;
const examsUrl=process.env.REACT_APP_EXAMS_URL;

function ExamResult(props) {
   const contentRef = useRef(null);
  const history = useHistory();
  const { examSubject, examId, examSetId } = useParams();
  const [examData, setExamData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showDetailBreakdown, setShowDetailBreakdown] = useState(false);
  const handleToggleDetailBreakdown = () => {
    setShowDetailBreakdown(!showDetailBreakdown);
  };

  console.log(examId);

  useEffect(() => {
    fetchExamData();
  }, []);


  const fetchExamData = async () => {
    //const examId = props.match.params.id;
    try {
      const response = await axios.get(`${examsUrl}result.php?examId=${examId}`);
      const result = response.data; // Assuming the data is returned as an array
      setExamData(result);
      setLoading(false);
      console.log(result);
      
    } catch (error) {
      console.error('Error fetching exam data:', error);
      setLoading(false);
    }
  };

function WrongQuestionsList({ wrongQuestions }) {
  return (
    <ul>
      {wrongQuestions.map((question, index) => (
        <li key={index}>
          <span>&#10060;</span> {question}
        </li>
      ))}
    </ul>
  );
}
function CorrectQuestionsList({ correctQuestions }) {
  return (
    <ul>
      {correctQuestions.map((question, index) => (
        <li key={index}>
          <span>&#10004;</span> {question}
        </li>
      ))}
    </ul>
  );
}

  const handleBackToHome = async () => {
      const path = `/`;
      history.push(path);
  }

const handleDownloadClick = async () => {
  const examPercent = Math.round((examData.analysisSummary.correctAnswers.length / examData.examSummary.ExamQuestions) * 100);
  const examId = props.match.params.id;
  const certificateExaTitle = `${examData.examSummary.ExamHeadline}`;
  // Replacing spaces with non-breaking spaces
  const modifiedTitle = certificateExaTitle.split(' ').join('\u00A0');
  
  
  console.log(modifiedTitle);
  const certificateData = {
    candidate: '',
    expertiseLevel: '`${examData.analysisSummary.expertiseLevel}`',
    totalQuestions: '`${examData.examSummary.ExamQuestions}`',
    totalAnswer: 18,
    totalWrongAnswer: 2,
    percentage: '`{Math.round((examData.analysisSummary.correctAnswers.length / examData.examSummary.ExamQuestions) * 100)}%`',
    timeTaken: '`{Math.round(examData.examSummary.ExamTime / 60)} minutes`',
    barcode: 'XXXXX',
    providedBy: logoImage,
    providedAt: 'generated time',
    result: `${examPercent}`,
    total: 100,
    examTitle: modifiedTitle, 
    examDate: 'May 26, 2023',
    qrCodeDataUrl: `${verificationUrl}?examId=${examId}`, // Update the URL as needed
  };

  // Generate QR code image
  const qrCodeDataUrl = await QRCode.toDataURL(certificateData.qrCodeDataUrl);

  const options = {
    margin: [10, 0, 10, 0], // Top, Right, Bottom, Left margins
    filename: `${examId}-${examData.examSummary.ExamTechnology}.pdf`,
    image: { type: 'png', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  };

  const certificateContent = (
    <div style={{ width: '100%', height: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          padding: '30px',
          boxSizing: 'border-box',
        }}
      >
        <Certificate {...certificateData} qrCodeDataUrl={qrCodeDataUrl} />
      </div>
    </div>
  );

  const htmlContent = ReactDOMServer.renderToStaticMarkup(certificateContent);
  html2pdf().set(options).from(htmlContent).save();
};
{/* console.log(examData); */}
  return (
    <div className="examIntroScreen">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
        <PreventReloadComponent />
          <Grid container justifyContent="center" alignItems="center" className="examIntro">
              <Box maxWidth="900px" margin="auto" className="examIntroBox">
                <Box className="examIntroBoxTitle">
                <Typography variant="h3" className="expertiseHeadline"> Congratualation!</Typography>
                 <Typography variant="body1" className="Text-Color">
                 Congratulations on completing the exam! Detailed analysis of your performance is provided below.
                  </Typography>
                  <Typography variant="h4" className="expertiseHeadline"> Your Score:  <b>{examData.totalCorrect}%</b></Typography>
              </Box>
               
       <div className="detailInformation">
      <Typography variant="h6" className="quizInfo" onClick={handleToggleDetailBreakdown}>
        <span className="detailBreakdown">Analysis:</span>
        <Typography variant="body1" className="Text-Color">
                  Display a complete list of the questions you've attempted, with a tick mark indicating correct answers and a cross mark signifying incorrect ones.
                  </Typography>
      </Typography>

      {showDetailBreakdown && (
        <div className="toggleInformationBlock">
          <Box textAlign="left" my={2} className="quizFacts">
          {/*
            <Typography variant="h6" className="quizInfo">
              <span className="quizText">Technology:</span> 
            </Typography>
            <Typography variant="h6" className="Text-Color">
              <span className="quizText">Practice Level:</span> 
            </Typography>
             <Typography variant="h6" className="Text-Color">
              <span className="quizText">Percentage:</span> {examData.totalCorrect}%
            </Typography>
            <Typography variant="h6" className="Text-Color">
              <span className="quizText">Exam Topics:</span> 
            </Typography>
            <Typography variant="h6" className="Text-Color">
              <span className="quizText">Total:</span>  Questions
            </Typography>
            <Typography variant="h6" className="Text-Color">
              <span className="quizText">Question Style:</span>
            </Typography>
            <Typography variant="h6" className="Text-Color">
              <span className="quizText">Time:</span>  minutes
            </Typography>
          */}
            <Typography variant="h6" className="Text-Color wrongAnswerText">
              <span className="quizText">Wrong Answers:</span>
              <WrongQuestionsList wrongQuestions={examData.wrongTitles} />
            </Typography>

            <Typography variant="h6" className="Text-Color correctAnswerText">
              <span className="quizText">Correct Answers:</span>
              <CorrectQuestionsList correctQuestions={examData.correctTitles} />
            </Typography>

            
          </Box>
        </div>
      )}
    </div>

  {/* 
                <Box textAlign="left" my={2}>
                  <Typography variant="h6" className="quizInfo quizNote">
                    Note:
                  </Typography>
                  <Typography variant="body1" className="Text-Color">
                  Exam Note 
                  </Typography>
                </Box>

                <Box textAlign="left" my={2}>
                  <Typography variant="h6" className="quizInfo quizNote">
                    Disclaimer:
                  </Typography>
                  <Typography variant="body1" className="Text-Color">
                  ExamDisclaimer
                  </Typography>
                </Box>
                */}
                <Grid container justifyContent="center" spacing={2} className="introButtonsWrapper">
                  <Grid item xs={12} sm="auto" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      className="Space-Bottom primaryButtons"
                      variant="contained"
                     onClick={handleBackToHome}
                      color="primary"
                      size="large"
                      disableElevation
                      startIcon={<SearchIcon />}
                      
                      style={{ whiteSpace: 'nowrap', marginRight: '10px' }}
                    >
                      Explore
                    </Button>
                    {/*

                   
                    <Button
        className="Space-Bottom primaryButtons"
        variant="contained"
        color="primary"
        size="large"
        startIcon={<CloudDownloadIcon />}
        disableElevation
        style={{ whiteSpace: 'nowrap', marginLeft: '10px' }}
        onClick={handleDownloadClick}
      >
        Download
      </Button>
       */}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
        </>
      )}
    </div>
  );
}
export default ExamResult;
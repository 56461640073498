import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null); // Add token state

    useEffect(() => {
        // Initialize authentication state from local storage
        const storedToken = localStorage.getItem('jwt_token');
        const storedUserData = localStorage.getItem('user_data');
        if (storedToken && storedUserData) {
            setToken(storedToken); // Set the token
            setIsAuthenticated(true);
            setUser(JSON.parse(storedUserData)); // Parse the stringified user data
        }
    }, []);

    const login = (newToken, userData) => {
        localStorage.setItem('jwt_token', newToken);
        localStorage.setItem('user_data', JSON.stringify(userData)); // Stringify and store user data
        setToken(newToken); // Update token state
        setUser(userData);
        setIsAuthenticated(true);
    };

    const logout = () => {
        localStorage.removeItem('jwt_token');
        localStorage.removeItem('user_data');
        setToken(null); // Clear token state
        setIsAuthenticated(false);
        setUser(null);
    };

    const checkAccessAndGenerateLink = (examLevel, userExamId,examSetId, examSubject, selectedLang) => {
        const allowedLevels = ['novice', 'beginner', 'intermediate'];

        // Check if examLevel is defined and convert it to lower case
        const lowerCaseExamLevel = examLevel ? examLevel.toLowerCase() : '';

        if (!isAuthenticated && !allowedLevels.includes(lowerCaseExamLevel)) {
            return '/login'; // Redirect to login if user is not authenticated and exam level is not allowed
        }

        // Return the dynamic exam link using template literals
        return `/exam/${examSubject}/${userExamId}/${examSetId}/intro?lang=${selectedLang}`;
    };



    // The value object contains everything that the context will provide to the consuming components
    return (
        <AuthContext.Provider value={{ isAuthenticated, user, token, login, logout, checkAccessAndGenerateLink }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
import React, { useReducer, useEffect, useState } from 'react';
import { Grid, Button, Typography, ThemeProvider, createTheme } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DoubleArrowIcon from '@material-ui/icons/NavigateNext';
import KeyboardDoubleArrowLeftIcon from '@material-ui/icons/NavigateBefore';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import FlagIcon from '@material-ui/icons/Flag';
import ReplayIcon from '@material-ui/icons/Replay';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { Box } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import Question from './components/Question';
import SiteNavbar from './components/SiteHeader';
import SiteFooter from './components/SiteFooter';
import PreventCopyPaste from './components/PreventCopyPaste';
import PreventReloadComponent from './components/PreventReloadComponent';
import QuizContext from './context/QuizContext';
import './assets/styles/styles.css';


const examsUrl=process.env.REACT_APP_EXAMS_URL;
let url = new URL(window.location.href);
let lang = url.searchParams.get("lang");


function ExamIntro(props) {
  const {examId, examSetId } = useParams();
  const [examData, setExamData] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { technology, examLevel } = useParams();

  console.log(examData);

  const history = useHistory();

  const handleExploreClick = () => {
    history.push(`/examslist/${examData.ExamTechnology}/${examData.subjectID}/`);
  };


  const creatExamQuestion = async () => {

      const pathSegments = window.location.pathname.split('/');
  const technology = pathSegments[2];
    try {
      const response = await axios.get(`${examsUrl}examProcess.php?examId=${examId}&examSetId=${examSetId}&subject=${technology}`);
      
    } catch (error) {
      console.error('Error fetching exam data:', error);
      
    }
  };




  const fetchExamData = async () => {
  const pathSegments = window.location.pathname.split('/');
  const technology = pathSegments[2];
  const examId = pathSegments[3];
  const examLevel = "beginner";
  const selectedLang = lang;

    try {
      const response = await axios.get(`${examsUrl}examDetails.php?examId=${examId}&examSetId=${examSetId}&technology=${technology}&examLevel=${examLevel}&lang=${selectedLang}`);
      const data = response.data; // Assuming the data is returned as an array
      setExamData(data);
      setLoading(false);
      
    } catch (error) {
      console.error('Error fetching exam data:', error);
      setLoading(false);
    }
  };


  useEffect(() => {
    creatExamQuestion();
    fetchExamData();
  }, []);





  const handleBeginClick = () => {
    history.push(`/exam/${examData.ExamSubject}/${examData.UserExamId}/${examData.ExamId}/live?lang=${examData.ExamLanguage}`);
  };

  return (
    <div className="examIntroScreen">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
        <PreventReloadComponent />
        <PreventCopyPaste />
          <Grid container justifyContent="center" alignItems="center" className="examIntro">
              <Box maxWidth="900px" margin="auto" className="examIntroBox">
                <Typography variant="h3" color="inherit" align="center" className="Text-Color">
                  {examData.ExamHeadline}
                </Typography>
                <Box display="flex" justifyContent="center" my={2}>
                  <Typography variant="body1" color="inherit" align="center" className="Text-Color">
                    {examData.ExamDetails}
                        </Typography>
                </Box>
                <Box textAlign="left" my={2} className="quizFacts">
                  <Typography variant="h6" className="quizInfo">
                    <span className="quizText">Technology:</span> {examData.ExamSubject}
                  </Typography>
                  <Typography variant="h6" className="Text-Color">
                    <span className="quizText">Practice Level:</span> {examData.ExamLevel}
                  </Typography>
                  <Typography variant="h6" className="Text-Color">
                    <span className="quizText">Exam Topics:</span> {examData.ExamTopic}
                  </Typography>
                  <Typography variant="h6" className="Text-Color">
                    <span className="quizText">Total:</span> {examData.ExamQuestions} Questions
                  </Typography>
                  <Typography variant="h6" className="Text-Color">
                    <span className="quizText">Question Style:</span> {examData.ExamStyle}
                  </Typography>
                  <Typography variant="h6" className="Text-Color">
                    <span className="quizText">Time:</span> {examData.ExamTime} minutes
                  </Typography>
                </Box>
                <Box textAlign="left" my={2}>
                  <Typography variant="h6" className="quizInfo quizNote">
                    Note:
                  </Typography>
                  <Typography variant="body1" className="Text-Color">
                  {examData.ExamNote}
                  </Typography>
                </Box>

                <Box textAlign="left" my={2}>
                  <Typography variant="h6" className="quizInfo quizNote">
                    Disclaimer:
                  </Typography>
                  <Typography variant="body1" className="Text-Color">
                  {examData.ExamDisclaimer}
                  </Typography>
                </Box>
                <Grid container justifyContent="center" spacing={2} className="introButtonsWrapper">
                  <Grid item xs={12} sm="auto" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      className="Space-Bottom primaryButtons"
                      variant="contained"
                      onClick={handleExploreClick}
                      color="primary"
                      size="large"
                      disableElevation
                      startIcon={<SearchIcon />}
                      disabled={examData.totalQuestions === 0}
                      style={{ whiteSpace: 'nowrap', marginRight: '10px' }}
                    >
                      Explore
                    </Button>
                    <Button
                      className="Space-Bottom primaryButtons"
                      variant="contained"
                      color="primary"
                     onClick={handleBeginClick}
                      size="large"
                      startIcon={<PlayCircleFilledWhiteIcon />}
                      disableElevation
                      disabled={examData.totalQuestions === 0}
                      style={{ whiteSpace: 'nowrap', marginLeft: '10px' }}
                    >
                      {examData.totalQuestions === 0 ? 'Please wait' : 'Begin!'}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
        </>
      )}
    </div>
  );
}

export default ExamIntro;
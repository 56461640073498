import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SiteNavbar from './components/SiteHeader';
import WelcomePageComponent from './components/Welcome';
import SiteFooter from './components/SiteFooter';
import { fetchSiteData } from './components/SiteData';
import PreventCopyPaste from './components/PreventCopyPaste';

const useStyles = makeStyles((theme) => ({
  technologyNamesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30vh', // Set this to your desired height
    [theme.breakpoints.down('sm')]: {
      height: '50vh',
    },
  },
  siteHeadlineText: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  siteMainDetails: {
    textAlign: 'justify',
    maxWidth: '80%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      padding: theme.spacing(0, 2),
    },
  },
}));

function HomePage() {
    const [data, setData] = useState([]);
    const [appTitle, setAppTitle] = useState('');
    const [appShortDesc, setappShortDesc] = useState('');
    const [appCopyright, setappCopyright] = useState('');
    const [appHome, setappHome] = useState('');
    const [appSearchTerms, setappSearchTerms] = useState('');
    const [appSearchCatText, setappSearchCatText] = useState('');
    useEffect(() => {
    const fetchDataFromAPI = async () => {
      try {
        const apiData = await fetchSiteData();
        setData(apiData);
       // console.log(apiData);


        if (apiData.projectInfo) {
          setAppTitle(apiData.projectInfo.APP_TITLE);
          setappShortDesc(apiData.projectInfo.APP_SHORTDESCRIPTION);
           setappCopyright(apiData.projectInfo.APP_COPYRIGHT);
           setappHome(apiData.projectPages.home.menu_text);
           setappSearchCatText(apiData.projectInfoGeneral.APP_SEARCH_CATEGORIES);
           setappSearchTerms(apiData.projectInfoGeneral.APP_SEARCH);
        }
        
      } catch (error) {
        // Handle the error
      }
    };

    fetchDataFromAPI();
  }, []);
    //console.log(appSearchTerms);
  const classes = useStyles();
  //console.log({data.projectInfo.APP_NAME});

  return (
    <React.Fragment>
    <PreventCopyPaste />
      <SiteNavbar  Home={appHome}/>
     <div className={`${classes.technologyNamesWrapper} welcomeWrapper`}>
        <Typography
          variant="h2"
          component="h1"
          className={`${classes.siteHeadlineText} examsHeadlineText`}
          align="center"
          gutterBottom
        >
          {appTitle}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          className={classes.siteMainDetails}
          align="center"
        >
         {appShortDesc}
        </Typography>
      </div>

      <WelcomePageComponent searchTermText={appSearchTerms}  searchCatText={appSearchCatText}/>
      <SiteFooter CopyRight={appCopyright} />
    </React.Fragment>
  );
}

export default HomePage;
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route } from 'react-router-dom';
import Modal from 'react-modal';
import './assets/styles/styles.css';
import App from './App';
import PreventCopyPaste from './components/PreventCopyPaste';
import * as serviceWorker from './serviceWorker';
import AuthProvider from './context/AuthContext'; // Import AuthProvider

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider> {/* Wrap your Router with AuthProvider */}
      <Router basename={process.env.PUBLIC_URL}>
        <PreventCopyPaste />
        <Route path="/:id?" component={App} />
      </Router>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
import axios from 'axios';
const siteDataUrl = process.env.REACT_APP_BUILDER_URL;
export const fetchSiteData = async () => {
  const queryParams = new URLSearchParams(window.location.search);
  const lang = queryParams.get('lang') || 'en';

  try {
    const response = await axios.get(`${siteDataUrl}?lang=${lang}`);
    return response.data[0];
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

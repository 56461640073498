import React, { useState, useEffect, useContext } from "react";
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeIcon from '@material-ui/icons/Home';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // Adjust the path as needed

function SiteNavbar(props) {
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [selectedLang, setSelectedLang] = useState('en');
  const { isAuthenticated, user, logout } = useContext(AuthContext);

// In SiteNavbar.js, at the beginning of the component function
//console.log("User in SiteNavbar:", user);

const handleProfileClick = () => {
    if (user && user.user_data.user_id) {
        history.push(`/profile/${user.user_data.user_id}`);
    }
};

const handlePassReset = () => {
        history.push(`/passreset/`);
    
};



  useEffect(() => {
    const lang = localStorage.getItem('selectedLang');
    if (lang) {
      setSelectedLang(lang);
    }
  }, []);

  const toggleNavbar = () => {
    setExpanded(!expanded);
  };

  const handleLangSelection = (lang) => {
    setSelectedLang(lang);
    localStorage.setItem('selectedLang', lang);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('lang', lang);
    history.push({ search: queryParams.toString() });
  };


  return (
    <Navbar bg="light" expand="lg" expanded={expanded}>
      <Container>
        <Navbar.Brand href={`/?lang=${selectedLang}`}>
          <img
            src="/assets/images/branding/logo.png"
            width="200"
            className="d-inline-block align-top"
            alt="logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={toggleNavbar}
        >
          {expanded ? <CloseIcon /> : <span className="navbar-toggler-icon" />}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link href="/" onClick={toggleNavbar}>
              <HomeIcon /> {props.Home}
            </Nav.Link>
            {/* User Dropdown - Visible if authenticated */}
            {isAuthenticated && user && (
              <Nav.Link href="#" className="userDropdown">
              <Dropdown align="end" className="userDropdown">
                <Dropdown.Toggle variant="light" id="user-dropdown">
                  {user.user_data.display_name} 
                  <img src={user.user_data.avatar} alt={user.username} width="30" height="30" /> {/* Assuming 'image' and 'username' are part of user object */}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleProfileClick}>Profile</Dropdown.Item>
                   <Dropdown.Item onClick={handlePassReset}>Password Reset</Dropdown.Item>
                  <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              </Nav.Link>
            )}

             {!isAuthenticated && (
        <Nav.Link href="/login">
            <LockIcon /> Login
        </Nav.Link>
    )}
    
            {/* Language Selector Dropdown */}
          <Dropdown align="end" className="languageSelector">
              <Dropdown.Toggle variant="light" id="languages-dropdown">
                <img
                  src={`/assets/images/flags/${selectedLang}.png`}
                  alt={selectedLang}
                  width="16"
                  height="16"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu >
                <Dropdown.Item onClick={() => handleLangSelection('en')}>
                  <img
                    src="/assets/images/flags/en.png"
                    alt="English"
                    width="16"
                    height="16"
                  />{" "}
                  English
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleLangSelection('ar')}>
                  <img
                    src="/assets/images/flags/ar.png"
                    alt="Arabic"
                    width="16"
                    height="16"
                  />{" "}
                  Arabic
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleLangSelection('bd')}>
                  <img
                    src="/assets/images/flags/bd.png"
                    alt="Bangla"
                    width="16"
                    height="16"
                  />{" "}
                  Bangla
                </Dropdown.Item>
                 <Dropdown.Item onClick={() => handleLangSelection('ch')}>
                  <img
                    src="/assets/images/flags/ch.png"
                    alt="Chinese"
                    width="16"
                    height="16"
                  />{" "}
                  Chinese
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleLangSelection('fr')}>
                  <img
                    src="/assets/images/flags/fr.png"
                    alt="French"
                    width="16"
                    height="16"
                  />{" "}
                  French
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleLangSelection('de')}>
                  <img
                    src="/assets/images/flags/de.png"
                    alt="German"
                    width="16"
                    height="16"
                  />{" "}
                  German
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleLangSelection('hi')}>
                  <img
                    src="/assets/images/flags/hi.png"
                    alt="Hindi"
                    width="16"
                    height="16"
                  />{" "}
                  Hindi
                </Dropdown.Item>

                <Dropdown.Item onClick={() => handleLangSelection('es')}>
                  <img
                    src="/assets/images/flags/es.png"
                    alt="Spanish"
                    width="16"
                    height="16"
                  />{" "}
                  Spanish
                </Dropdown.Item>

              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default SiteNavbar;
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@material-ui/icons/Info';
import DoubleArrowIcon from '@material-ui/icons/NavigateNext';
import DoubleArrowIconPrevious from '@material-ui/icons/NavigateBefore';
import { useParams, useLocation } from 'react-router-dom';
import ReplayIcon from '@material-ui/icons/Replay';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from 'react-select';
import { fetchSiteData } from './SiteData';
import { AuthContext } from '../context/AuthContext';
// Importing icons from @material-ui/icons
import EcoIcon from '@material-ui/icons/Eco'; // For Novice
import BookIcon from '@material-ui/icons/Book'; // For Beginner
import ExploreIcon from '@material-ui/icons/Explore'; // For Intermediate
import WorkIcon from '@material-ui/icons/Work'; // For Professional
import GradeIcon from '@material-ui/icons/Grade'; // expert
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'; // Alternative for Genius, since a direct brain icon isn't available
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions'; // Custom choice for Master, as Crown is not directly available
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter'; // Custom choice for Ninja, as a direct ninja icon isn't available
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents'; // For Champion
import StarIcon from '@material-ui/icons/Star'; // For Legend
import AccountCircleIcon from '@material-ui/icons/AccountCircle'; // Custom choice for Guru
import ImportContactsIcon from '@material-ui/icons/ImportContacts'; // Custom choice for Sage
import MusicNoteIcon from '@material-ui/icons/MusicNote'; // Custom choice for Maestro
import BuildIcon from '@material-ui/icons/Build'; // For Architect
import VisibilityIcon from '@material-ui/icons/Visibility'; // For Visionary
import SettingsIcon from '@material-ui/icons/Settings'; // For Innovator
import BubbleChartIcon from '@material-ui/icons/BubbleChart'; // Custom choice for Mystic
import QueueMusicIcon from '@material-ui/icons/QueueMusic'; // Custom choice for Virtuoso
import SecurityIcon from '@material-ui/icons/Security'; // Custom choice for Guardian



const examUrl = process.env.REACT_APP_EXAMS_URL;

const examsLists = () => {
    const [data, setData] = useState({ examList: [], totalExams: 0, perPage: 10, examComplexities: {}, uniqueTopics: {}, uniqueTags: {} });
    const [loading, setLoading] = useState(true);
    const { token, user, isAuthenticated, checkAccessAndGenerateLink } = useContext(AuthContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchCat, setSearchCat] = useState('all');
    const [searchTag, setSearchTag] = useState('all');
    const [searchLevel, setSearchLevel] = useState('all');
    const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const selectedLang = searchParams.get('lang') || 'en';
    {/* const selectedTech = 'javascript'; */}
    const { examSubject, examSubjectId } = useParams();
    const [siteData, setSiteData] = useState({});
    const [appLevelText, setappLevelText] = useState('');
    const [appTopicText, setappTopicText] = useState('');
    const [appTagsText, setappTagsText] = useState('');
    const [appExamStartText, setappExamStartText] = useState('');
    const [appNextText, setappNextText] = useState('');
     const [appLearnText, setappLearnText] = useState('');
    const [appPreviousText, setappPreviousText] = useState('');
     const [appNoExamText, setappNoExamText] = useState('');

   // Convert your data structures to the react-select format
    const examComplexitiesOptions = data.examComplexities 
        ? Object.entries(data.examComplexities).map(([key, value]) => ({ value: value, label: value }))
        : [];
    
const uniqueTopicsOptions = data.uniqueTopics 
    ? Object.entries(data.uniqueTopics).map(([key, value]) => ({ value: value, label: value }))
    : [];

    
    const uniqueTagsOptions = data.uniqueTags 
        ? Object.entries(data.uniqueTags).map(([key, value]) => ({ value: value, label: value }))
        : [];

console.log(uniqueTagsOptions);
const handleReset = () => {
    setSearchTerm('');
    setSearchCat('all');
    setSearchTag('all');
    setSearchLevel('all');
    setCurrentPage(1);
    fetchData();
};


// Mapping levels to icons
const levelIcons = {
  Novice: EcoIcon,
  Beginner: BookIcon,
  Intermediate: ExploreIcon,
  Professional: WorkIcon,
  Expert : GradeIcon,
  Genius: EmojiObjectsIcon,
  Master: EmojiEmotionsIcon,
  Ninja: FitnessCenterIcon,
  Champion: EmojiEventsIcon,
  Legend: StarIcon,
  Guru: AccountCircleIcon,
  Sage: ImportContactsIcon,
  Maestro: MusicNoteIcon,
  Architect: BuildIcon,
  Visionary: VisibilityIcon,
  Innovator: SettingsIcon,
  Mystic: BubbleChartIcon,
  Virtuoso: QueueMusicIcon,
  Guardian: SecurityIcon,
};


// Component to display icon based on the level, with dynamic class and id
const SkillLevelIcon = ({ level, className, id }) => {
  const IconComponent = levelIcons[level];
  
  // Using a template literal to dynamically generate a base class name if not provided
  const dynamicClass = className || `icon-${level.toLowerCase()}`;
  const dynamicId = id || `icon-id-${level.toLowerCase()}`;

  return IconComponent ? <IconComponent className={dynamicClass} id={dynamicId} /> : null;
};


//console.log("Testing loggedin user", user);

    useEffect(() => {
        fetchData();
    }, [currentPage, searchTerm, searchCat, searchTag, searchLevel, selectedLang]);



    const fetchData = async () => {
        const url = `${examUrl}dexams.php?pageId=${currentPage}&examSubject=${examSubject}&searchTerm=${searchTerm}&examTopic=${searchCat}&examTags=${searchTag}&examComplexity=${searchLevel}&lang=${selectedLang}`;
        setLoading(true);
        try {
            const response = await axios.get(url);
            setData(response.data);

 const fetchedData = await fetchSiteData(/* provide any required parameters here, if needed */);
                setSiteData(fetchedData); // Step 2: Fetch site data and set it in state
        

           setappLevelText(fetchedData.projectInfoGeneral.APP_EXAM_LEVEL);
           setappTopicText(fetchedData.projectInfoGeneral.APP_EXAM_TOPIC);
           setappTagsText(fetchedData.projectInfoGeneral.APP_EXAM_TAGS);
           setappExamStartText(fetchedData.projectInfoGeneral.APP_EXAM_START);
           setappNextText(fetchedData.projectInfoGeneral.APP_PAGE_NEXT);
           setappPreviousText(fetchedData.projectInfoGeneral.APP_PAGE_PREVIOUS);
           setappNoExamText(fetchedData.projectInfoGeneral.APP_EXAM_EMPTY);
           setappLearnText(fetchedData.projectInfoGeneral.APP_EXAM_LEARN);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearch = () => {
        fetchData();
    };

    const renderPagination = () => {
        if (loading || !data.examList || data.totalExams === 0) {
            return null;
        }

        const totalPages = Math.ceil(data.totalExams / data.perPage);

        if (totalPages <= 1) {
            return null;
        }

        const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

        return (
            <div className="paginationWrapper">
                <button
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                    className={`paginationButton ${currentPage === 1 ? 'disabled' : ''}`}
                >
                    <DoubleArrowIconPrevious /> {appPreviousText}
                </button>
                {pageNumbers.map((pageNumber) => (
                    <button
                        key={pageNumber}
                        className={`paginationButton ${currentPage === pageNumber ? 'active' : ''}`}
                        onClick={() => handlePageChange(pageNumber)}
                    >
                        {pageNumber}
                    </button>
                ))}
                <button
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                    className={`paginationButton ${currentPage === totalPages ? 'disabled' : ''}`}
                >
                    {appNextText} <DoubleArrowIcon />
                </button>
            </div>
        );
    };

    useEffect(() => {
        setIsSearchButtonDisabled(!(searchTerm || searchCat || searchTag || searchLevel));
    }, [searchTerm, searchCat, searchTag, searchLevel]);

    return (
        <div className="technologyNamesWrapper">

            <div className="searchInputWrapper ">
                <div className="search-container">
                <div className="flex-container filterContainer">
             <Select 
    className="select-item"
    placeholder={appLevelText}
    value={examComplexitiesOptions.find(option => option.value === searchLevel)}
    onChange={option => setSearchLevel(option.value)}
    options={[{ value: 'all', label: appLevelText }, ...examComplexitiesOptions]}
/>


 <Select 
    className="select-item"
    placeholder={appTopicText}
    value={uniqueTopicsOptions.find(option => option.value === searchCat)}
    onChange={option => setSearchCat(option.value)}
    options={[{ value: 'all', label: appTopicText }, ...uniqueTopicsOptions]}
/>


 <Select 
    className="select-item"
    placeholder={appTagsText}
    value={uniqueTagsOptions.find(option => option.value === searchTag)}
    onChange={option => setSearchTag(option.value)}
    options={[{ value: 'all', label: appTagsText }, ...uniqueTagsOptions]}
/>

               
                <div className="btn-container filterButtons">
                <button onClick={handleSearch} disabled={isSearchButtonDisabled || loading} className="btn btn-secondary searchBtnText">
                    <SearchIcon />
                </button>
                <button onClick={handleReset} disabled={loading} className="btn btn-secondary resetBtnText">
                    <ReplayIcon />
                </button>
            </div>
                </div>
                </div>
            </div>

      
           
    <ul className="container itemListWrapper">
    {
        loading ? (
            <li className="examLoadingContainer"> <div className="loadingContainer"><CircularProgress /> Loading exams...</div></li>
        ) : data.examList && data.examList.length ? (
            data.examList.map((exam, index) => (
                <li key={exam.id} className="mainListLi examItem">
            <a href={`/exam/${data.examSubject}/${exam.userExamId}/intro`} target="_blank">
 <SkillLevelIcon
                            level={exam[`level`]}
                            className={`examslist ${exam[`level`]} exam${data.examSubject} exam${data.examSubject}${exam[`level`]} exam${data.examSubject}${exam[`level`]}${index}`} // Modified to use `data.examSubject` and include `index`
                            id={`unique${data.examSubject}${index}`} // Modified to include `index`
                        />

            </a>     <div className="practiceExamLink">
                     <h1 className="technologyHeadline freeExamsHeadline ">{exam[`title`]}</h1>
                    <p className="examDescription">{exam[`description`]}</p>
            <a href={`/exam/details/${examSubject}/${exam.userExamId}/${exam.examSetId}?lang=${selectedLang}&subId=${examSubjectId}`} className="examList detailsLink freeExamsHeadline">
<h5 className="freeExamsHome"> {appLearnText} {<InfoIcon />}</h5>

 </a>


               <a className="detailsLink freeExamsHeadline" href={checkAccessAndGenerateLink(exam[`level`], exam.userExamId,exam.examSetId, examSubject, selectedLang)} target="_blank">
               <h5 className="freeExamsHeadline"> {appExamStartText} <DoubleArrowIcon />  </h5>
              </a>

              </div>
                </li>
            ))
        ) : (
            <li className="noExamFound">
           {appNoExamText}.   &nbsp; &nbsp;
            <button onClick={handleReset} disabled={loading} className="btn btn-secondary resetBtnText">
                <ReplayIcon />
            </button>
        </li>
        )
    }
</ul>

          
            {renderPagination()}
        </div>
    );
};

export default examsLists;
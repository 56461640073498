import React, { useState, useEffect } from "react";
import axios from 'axios';
import SiteNavbar from '../SiteHeader';
import SiteFooter from '../SiteFooter';
import { fetchSiteData } from '../SiteData';

const Register = () => {
    const [formData, setFormData] = useState({ username: '', email: '', password: '', confirmPassword: '' });
    const [errors, setErrors] = useState({});
    const [appCopyright, setAppCopyright] = useState('');
    const [appHome, setAppHome] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [showMessage, setShowMessage] = useState(true);
    const [showError, setShowError] = useState(true);

   const handleCloseMessage = () => {
        setShowMessage(false);
    };

    const handleCloseError = () => {
        setShowError(false);
    };

    useEffect(() => {
        const fetchDataFromAPI = async () => {
            try {
                const apiData = await fetchSiteData();
                if (apiData.projectInfo) {
                    setAppCopyright(apiData.projectInfo.APP_COPYRIGHT);
                    setAppHome(apiData.projectPages.home.menu_text);
                }
            } catch (error) {
                console.error('Error fetching site data:', error);
            }
        };

        fetchDataFromAPI();
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if (e.target.name === 'password' || e.target.name === 'confirmPassword') {
            if (formData.password !== formData.confirmPassword) {
                setErrors({ ...errors, password: 'Passwords do not match' });
            } else {
                let newErrors = { ...errors };
                delete newErrors.password;
                setErrors(newErrors);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');
        setShowMessage(true);
        setShowError(true);
        if (formData.password !== formData.confirmPassword) {
            setErrors({ ...errors, password: 'Passwords do not match' });
            return;
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                // Additional headers if needed
            }
        };

        try {
            const response = await axios.post(`https://userapi.trialtest.ai/wp-json/custom/v1/register`, JSON.stringify(formData), config);
            console.log('Registration Success:', response.data);
            setMessage('Success: ' + response.data.message);
            // Success logic here
        } catch (error) {
            console.error('Registration Error:', error.response ? error.response.data : error.message);
            // Error handling logic here
             setError('Error: ' + error.response.data.message);
        }
    };

    return (
        <React.Fragment>
            <SiteNavbar Home={appHome} />
            <div className="mainWrapper">
                <div className="loginForm registrationForm">
                    <h2 className="loginFormHeadline">Please Register</h2>
        {message && showMessage && (
                <div className="alert alert-success">
                <button type="button" className="close" onClick={handleCloseMessage}>
                        &times;
                    </button>
                    {message}
                    
                </div>
            )}
            {error && showError && (
                <div className="alert alert-danger">
                <button type="button" className="close" onClick={handleCloseError}>
                        &times;
                    </button>
                    {error}
                    
                </div>
            )}
                    <form onSubmit={handleSubmit}>
                    
                    <div className="formContainer">
                        <label className="label text-uppercase" >Username:</label>
                        <input className="form-control" type="text" name="username" value={formData.username} onChange={handleChange} required />
                    </div>
                    <div className="formContainer">
                        <label className="label text-uppercase">Email:</label>
                        <input className="form-control" type="email" name="email" value={formData.email} onChange={handleChange} required />
                    </div>
                        <div className="formContainer">
                            <label className="label text-uppercase">Password:</label>
                            <input className="form-control" type="password" name="password" value={formData.password} onChange={handleChange} required />
                        </div>
                        <div className="formContainer">
                            <label className="label text-uppercase">Confirm Password:</label>
                            <input className="form-control" type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required />
                            {errors.password && <div className="error">{errors.password}</div>}
                        </div>
                        <button className="btn btn-secondary" type="submit">Register</button>
                    </form>
                    <div className="quickLinks">
                      <a className="quickLinkRegister" href="/login">Login</a> | 
                      <a className="quickLinkPassreset" href="/passreset">Pass Reset</a>
                    </div>
                </div>
            </div>
            <SiteFooter CopyRight={appCopyright} />
        </React.Fragment>
    );
};

export default Register;
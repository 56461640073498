import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';

// Custom code block component for markdown
const CodeBlock = ({ language, value }) => (
    <SyntaxHighlighter language={language} style={solarizedlight}>
        {value}
    </SyntaxHighlighter>
);

function Answer({ answer, index }) {
    return (
        <Paper elevation={0} className="Paper">
            <FormControlLabel
                className="Full-Width"
                value={index}
                control={<Radio color="primary" />}
                label={
                    <ReactMarkdown 
                        components={{ 
                            code: ({node, inline, className, children, ...props}) => {
                                const match = /language-(\w+)/.exec(className || '')
                                return !inline && match ? 
                                    <CodeBlock language={match[1]} value={String(children).replace(/\n$/, '')} /> :
                                    <code className={className} {...props}>{children}</code>
                            }
                        }}
                    >
                        {answer}
                    </ReactMarkdown>
                }
            />
        </Paper>
    );
}

export default Answer;
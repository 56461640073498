import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import SiteNavbar from '../SiteHeader';
import SiteFooter from '../SiteFooter';

const Passreset = () => {
    const {user, token } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [showMessage, setShowMessage] = useState(true);
    const [showError, setShowError] = useState(true);

     const handleCloseMessage = () => {
        setShowMessage(false);
    };

    const handleCloseError = () => {
        setShowError(false);
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');
        setShowMessage(true);
        setShowError(true);

         const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        if (user && token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        let payload;
        let endpoint;

        if (user) {
            // For logged-in user, change password
            payload = {
                id: user.user_data.user_id,
                email: user.user_data.email,
                username: user.user_data.username,
                current_password: currentPassword,
                new_password: newPassword,
                confirm_password: confirmPassword
            };
            endpoint = `https://userapi.trialtest.ai/wp-json/custom/v1/change-password/${user.user_data.user_id}`; // Assuming user object has user_id
        } else {
            // For non-logged-in user, reset password
            payload = { email, username };
            endpoint = `https://userapi.trialtest.ai/wp-json/custom/v1/reset-password`;
        }

        try {
            const response = await axios.post(endpoint, JSON.stringify(payload), config);
            setMessage('Success: ' + response.data.message);
        } catch (error) {
            setError('Error: ' + error.response.data.message);
        }
    };
//console.log(user);
    return (
        <React.Fragment>
            <SiteNavbar />
            <div className="mainWrapper">
                <div className="loginForm registrationForm">
                    <h2 className="loginFormHeadline">{user ? 'Change Password' : 'Reset Password'}</h2>

            {message && showMessage && (
                <div className="alert alert-success">
                <button type="button" className="close" onClick={handleCloseMessage}>
                        &times;
                    </button>
                    {message}
                    
                </div>
            )}
            {error && showError && (
                <div className="alert alert-danger">
                <button type="button" className="close" onClick={handleCloseError}>
                        &times;
                    </button>
                    {error}
                    
                </div>
            )}

                     <form onSubmit={handleSubmit}>
                        {user ? (
                            <>
                            <input className="form-control" type="hidden" name="id" value={user.user_data.user_id}  />
                            <input className="form-control" type="hidden" name="email" value={user.user_data.email}  />
                            <input className="form-control" type="hidden" name="username" value={user.user_data.username}  />


                                <div className="formContainer">
                                    <label className="label text-uppercase">Curret Password </label>
                                    <input className="form-control" type="password" value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} placeholder="Current Password" />
                                </div>
                                <div className="formContainer">
                                    <label className="label text-uppercase">New Password </label>
                                    <input className="form-control" type="password" value={newPassword} onChange={e => setNewPassword(e.target.value)} placeholder="New Password" />
                                </div>
                                <div className="formContainer">
                                   <label className="label text-uppercase">Confirm Password </label>
                                    <input className="form-control" type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} placeholder="Confirm New Password" />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="formContainer">
                                    <label className="label text-uppercase">Email </label>
                                    <input className="form-control" type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" required />
                                </div>
                                <div className="formContainer">
                                  <label className="label text-uppercase">Username </label>
                                    <input className="form-control" type="text" value={username} onChange={e => setUsername(e.target.value)} placeholder="Username" required />
                                </div>
                            </>
                        )}
                        <button className="btn btn-secondary" type="submit">{user ? 'Change Password' : 'Reset Password'}</button>
                    </form>
               

                 <div className="quickLinks">
                  <a className="quickLinkRegister" href="/register">Register</a> | 
                  <a className="quickLinkPassreset" href="/login">Login</a>
                </div>
                 </div>

            </div>
            <SiteFooter />
        </React.Fragment>
    );
};

export default Passreset;

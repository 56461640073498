import React, { useState, useEffect, useContext } from "react";
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeIcon from '@material-ui/icons/Home';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext'; // Adjust the path as needed
import SiteNavbar from '../SiteHeader';
import SiteFooter from '../SiteFooter';
import { fetchSiteData } from '../SiteData';

const Logout = ({ onLogout }) => {
    const handleLogout = () => {
        // Clear authentication token
        // Redirect to login page or update the application state
        onLogout();
    };

    return <button onClick={handleLogout}>Logout</button>;
};

export default Logout;

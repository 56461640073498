import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Timer from './Timer'; // Assuming the Timer component file is in the same directory
const Progress = React.memo(({ current, total }) => {
  return (
   <Grid container justify="space-between" className="progressWrapper">
      <Typography variant="h6" color="inherit" className="progressLink topTimer" align="center" style={{ float: 'left', marginLeft: 0 }}>
        <Timer timeout={700} />
      </Typography>
      <Typography variant="h6" className="progressLink topText" color="inherit" align="center" style={{ float: 'right', marginRight: 0 }}>
        Question {current} of {total}
      </Typography>
    </Grid>
  );
});

export default Progress;
import React, { useState, useEffect } from 'react';

const PreventReloadComponent = ({ url }) => {
  const [confirmNavigation, setConfirmNavigation] = useState(false);

  const handleBeforeUnload = (event) => {
    if (!confirmNavigation) {
      event.preventDefault();
      event.returnValue = ''; // Required for Chrome
    }
  };

  const handleConfirmNavigation = () => {
    setConfirmNavigation(true);
    window.location.href = url;
  };

  useEffect(() => {
    const handleWindowUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; // Required for Chrome
      setConfirmNavigation(true);
    };

    window.onbeforeunload = handleBeforeUnload;
    window.addEventListener('unload', handleWindowUnload);

    return () => {
      window.onbeforeunload = null;
      window.removeEventListener('unload', handleWindowUnload);
    };
  }, []);

  if (!confirmNavigation) {
    return null; // Hide the component if not confirmed
  }

  return (
    <div>
      <h1>Prevent Reload Component</h1>
      <p>
        If you close the window or reload, your exam will start from the
        beginning.
      </p>
      <button onClick={handleConfirmNavigation}>Confirm Navigation</button>
    </div>
  );
};

export default PreventReloadComponent;
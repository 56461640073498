import React, { useContext, useEffect } from 'react';
import Answer from './Answer';
import Grid from '@material-ui/core/Grid';
import Progress from './Progress';
import Alert from '@material-ui/lab/Alert';
import QuizContext from '../context/QuizContext';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SET_CURRENT_ANSWER, SET_ERROR } from '../reducers/Const';
import { useParams } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';

const importAll = (requireContext) => {
  return requireContext.keys().reduce((images, next) => {
    images[next.replace('./', '')] = requireContext(next).default;
    return images;
  }, {});
};

const images = importAll(require.context('../assets/images', false, /\.(png|jpe?g|svg)$/));

const Question = () => {
  const { state, dispatch } = useContext(QuizContext);
  const { currentAnswer, currentQuestion, Questions, error } = state;
  const question = Questions && Questions[currentQuestion];
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://skillstest.me/mock-test/api.php?examName=${id}`);
        dispatch({ type: 'SET_QUESTIONS', questions: response.data });
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (event) => {
    dispatch({
      type: SET_CURRENT_ANSWER,
      currentAnswer: event.target.value,
    });
    dispatch({ type: SET_ERROR, error: '' });
  };

  if (!question) {
    return (
      <div className="loadingContainer">
        <CircularProgress />
      </div>
    );
  }

  const renderAnswerText = (text) => {
    return text.includes('<') ? text : ReactHtmlParser(text);
  };

  return (
    <div className="Questions">
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} md={8} className="Grid-Item">
          <Progress total={Questions.length} current={currentQuestion + 1} />
          {error && <Alert className="Alert" severity="error">{error}</Alert>}
          <FormControl component="fieldset" className="Full-Width">
            <Typography variant="h4" color="inherit" align="center" className="Text-Color questionHeadline">
              {question.id}. {ReactHtmlParser(question.question)}
            </Typography>
            {question.code && (
              <div className="Code-Block">
                <SyntaxHighlighter showLineNumbers language="javascript" style={darcula}>
                  {question.code.join('\n')}
                </SyntaxHighlighter>
              </div>
            )}
            {question.image && <img className="Image" alt="" src={images[`${question.image}`]} />}
            <RadioGroup className="questionOptions" aria-label="answer" name="answers" value={currentAnswer} onChange={handleChange}>
              {question.answers.map((answer) => (
                <Answer key={answer.option} index={answer.option} answer={renderAnswerText(answer.text)} />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default Question;